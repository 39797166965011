/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import { useMediaQuery } from 'react-responsive'

import * as React from 'react';
import { MuiFileInput } from 'mui-file-input'
import MKDatePicker from "components/MKDatePicker";

// Material Kit 2 PRO React components
import MKBadge from "components/MKBadge";
import Stack from "@mui/material/Stack";
import MKButton from "components/MKButton";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//import MKSocialButton from "components/MKSocialButton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import OutlinedInput from '@mui/material/OutlinedInput';
import { contact,create_annonce } from "back_airtable";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// HelpCenter page sections
import SocialAnalytics from "pages/Support/HelpCenter/sections/SocialAnalytics";
import Faq from "pages/Support/HelpCenter/sections/Faq";
import Features from "pages/Support/HelpCenter/sections/Features";
import Contact from "pages/Support/HelpCenter/sections/Contact";
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Form from "components/FormContact";
// Images
import bgImage from "assets/images/bg3.jpg";

import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";


// Otis Kit PRO examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Images
import bgImage1 from "assets/images/examples/color1.jpg";
import bgImage2 from "assets/images/examples/color3.jpg";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Images
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const metiers = [
  'Médecin',
  'Kinésithérapeuthe',
  'Infirmière',
  'Aide-soignante',
  'ASH',
  'ASL',
  'Autre',
];
const type_contrat = [
  'Vacation',
  'CDD',
  'CDI',
];

function HelpCenter() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 824px)'
  })
  const [email, setEmail] = React.useState('');
  const [formulaire, setFormulaire] = React.useState('Professionnel de santé');
  const [nom, setNom] = React.useState('');
  const [prenom, setPrenom] = React.useState('');
  const [nomEtablissement, setNomEtablissement] = React.useState('');
  const [telephone, setTelephone] = React.useState('');
  const [dateDebutCDD, setDateDebutCDD] = React.useState(new Date());
  const [codePostal, setCodePostal] = React.useState('');
  const [posteEtablissement, setPosteEtablissement] = React.useState("");
  const [message, setMessage] = React.useState('');
  const [typeContratChercher, setTypeContratChercher] = React.useState([]);
  const [talentChercher, setTalentChercher] = React.useState([]);
  const [specialiteCabinet, setSpecialiteCabinet] = React.useState([]);
  const [typeEtablissement, setTypeEtablissement] = React.useState("");
  const [cursus, setCursus] = React.useState("");
  const [departement, setDepartement] = React.useState('');
  const [annee, setAnnee] = React.useState("");
  const [dureeContrat, setDureeContrat] = React.useState("");
  const [checkEtudiant, setCheckEtudiant] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState("");
  const [collapse, setCollapse] = useState(false);
  const headerRef = useRef(null);
  const refContact = useRef(null);
  const handleClick = (typeForm) => {
    refContact.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const handleChangeTalentChercher = (event) => {
    const {
      target: { value },
    } = event;
    setTalentChercher(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChange = (event) => {
    setCursus(event.target.value);
  };
  const handleChangeTypeEtablissement = (event) => {
    setTypeEtablissement(event.target.value);
  };
  const handleChangeTypeContratChercher = (event) => {
    setTypeContratChercher(event.target.value);
  };
  const handleChangeFile = (newFile) => {
    setFile(newFile)
  }
  const handleChangeDepartement = (event) => {
    setDepartement(event.target.value);
  };
  const handleChangeSpecialiteCabinet = (event) => {
    setSpecialiteCabinet(event.target.value);
  };
  const handleChangeAnnee = (event) => {
    setAnnee(event.target.value);
  };
  const handleChangeCheck = (event) => {
    if(checkEtudiant){
      setCheckEtudiant(false);
    }else{
      setCheckEtudiant(true);
    } 
  };
  const reset_state = () => {
    setEmail("");
    setFormulaire("Professionnel de santé");
    setNom("");
    setPrenom("");
    setNomEtablissement("");
    setTelephone("");
    setDateDebutCDD(new Date());
    setCodePostal("");
    setPosteEtablissement("");
    setMessage("");
    setTypeContratChercher([]);
    setTalentChercher([]);
    setSpecialiteCabinet([]);
    setTypeEtablissement("");
    setCursus("");
    setDepartement("");
    setAnnee("");
    setDureeContrat("");
    setCheckEtudiant(false);
    setFile(null);
  }
  const onSubmit = (email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message) => {
    
    /* console.log(typeEtablissement, typeof typeEtablissement)
    console.log(etab,etab.etab)
    console.log(typeForm) */
    //console.log(talentChercher[0] == "" ? ["Non renseigné"] : talentChercher)
    //contact(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message);
    if(prenom == "" || nom == "" || email == "" || telephone == "" || talentChercher == []){
      setError("Veuillez remplir tous les champs obligatoires")
    }else{
      setError("");
      //contact(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message);
      create_annonce(nom,prenom,codePostal,nomEtablissement,telephone,email,talentChercher,typeContratChercher,message)
      //toggleSnackbar();
      setError("Votre message a bien été envoyé")
      reset_state();
    }
        
  }

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.sorya.eu",
          label: "Connexion",
          color: "info",
        }}
        sticky
      />
      <div style={{ backgroundColor: "#EDFAF8" }}>
        <MKBox
          minHeight={isDesktopOrLaptop ?"55vh":"75vh"}
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.4),
                rgba(gradients.dark.state, 0.4)
              )}, url("https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695764995/Untitled_18_eao55p.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="whiteTitle">Publier une annonce</span>
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="whiteTitle">Pour publier une annonce, vous pouvez remplir le formulaire ci-dessous et vous faire rappeler par l’un de nos recruteurs.</span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "#EBF9F0",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
          bgColor="#0F2022"
        >
          {/* Nous contacter */}
          <div style={{marginTop:"80px"}} ref={refContact}>
            {/* <Form typeForm={1} etab={"EHPAD"}/> */}
            <Container>
          <Grid
            container
            item
            justifyContent="center"
            xs={10}
            lg={7}
            mx="auto"
            mb={{ xs: 0, md: 6 }}
            textAlign="center"
          >
            <MKTypography variant="h1" mb={1}>
              <span className="fontKionaGold">Formulaire</span>
            </MKTypography>
            {/* <MKTypography variant="body1" color="text">
              <span className="fontPara">
                Nous tâchons de répondre dans les plus brefs délais à vos demandes.
              </span>
            </MKTypography> */}
          </Grid>
          <Container>
         
              <AppBar position="static">
                {/* <Tabs value={activeTab} onChange={handleTabType} style={{backgroundColor:"#DBE5E2"}}>
                  <Tab label="Professionnel de santé" />
                  <Tab label="Etablissement" />
                </Tabs> */}
                <Grid container item xs={12} lg={8} sx={{ mx: "auto" }} py={3}>
                <MKBox width="100%" component="form" method="post" autocomplete="off">
                  <MKBox p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} >
                        <FormControl fullWidth >
                          <InputLabel id="demo-simple-select-label">Vous êtes</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeEtablissement}
                            label="Type d'établissement"
                            onChange={handleChangeTypeEtablissement}
                            style={{height:"42px"}}
                          >
                            <MenuItem value={"EHPAD"}>Un EHPAD</MenuItem>
                            <MenuItem value={"Hôpital et clinique"}>Un hôpital/clinique</MenuItem>
                            <MenuItem value={"Cabinet de ville"}>Un cabinet</MenuItem>
                            <MenuItem value={"Pharmacie"}>Une pharmacie</MenuItem>
                            <MenuItem value={"Autre"}>Autre</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {typeEtablissement !== "cabinet" ?
                      <Grid item xs={12} md={6}>
                        <MKInput value={nomEtablissement} onChange={(e)=>{setNomEtablissement(e.target.value)}} type="text" success label={<span >Nom de l'établissement*</span>} fullWidth />
                      </Grid>
                      : null }
                      <Grid item xs={12} md={6}>
                        <MKInput value={codePostal} onChange={(e)=>{setCodePostal(e.target.value)}} type="text" success label={<span >Code Postal*</span>} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput value={nom} onChange={(e)=>{setNom(e.target.value)}} type="text" success label={<span >Nom</span>} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput value={prenom} onChange={(e)=>{setPrenom(e.target.value)}} type="text" success label={<span >Prénom</span>} fullWidth />
                      </Grid>
                      { typeEtablissement === "cabinet" ?
                      <Grid item xs={12} md={6} >
                        <FormControl fullWidth >
                          <InputLabel id="demo-simple-select-label">Spécialité</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={specialiteCabinet}
                            label="Type d'établissement"
                            onChange={handleChangeSpecialiteCabinet}
                            style={{height:"42px"}}
                          >
                            <MenuItem value={"allergologie"}>allergologie</MenuItem>
                            <MenuItem value={"immunologie"}>immunologie</MenuItem>
                            <MenuItem value={"anesthésiologie"}>anesthésiologie</MenuItem>
                            <MenuItem value={"andrologie"}>andrologie</MenuItem>
                            <MenuItem value={"cardiologie"}>cardiologie</MenuItem>
                            <MenuItem value={"chirurgie cardiaque"}>chirurgie cardiaque</MenuItem>
                            <MenuItem value={"chirurgie esthétique, plastique et reconstructive"}>chirurgie esthétique, plastique et reconstructive</MenuItem>
                            <MenuItem value={"chirurgie générale"}>chirurgie générale</MenuItem>
                            <MenuItem value={"chirurgie maxillo-faciale"}>chirurgie maxillo-faciale</MenuItem>
                            <MenuItem value={"chirurgie pédiatrique"}>chirurgie pédiatrique</MenuItem>
                            <MenuItem value={"chirurgie thoracique"}>chirurgie thoracique</MenuItem>
                            <MenuItem value={"chirurgie vasculaire"}>chirurgie vasculaire</MenuItem>
                            <MenuItem value={"neurochirurgie"}>neurochirurgie</MenuItem>
                            <MenuItem value={"dermatologie"}>dermatologie</MenuItem>
                            <MenuItem value={"endocrinologie"}>endocrinologie</MenuItem>
                            <MenuItem value={"gastro-entérologie"}>gastro-entérologie</MenuItem>
                            <MenuItem value={"gériatrie"}>gériatrie</MenuItem>
                            <MenuItem value={"gynécologie"}>gynécologie</MenuItem>
                            <MenuItem value={"hématologie"}>hématologie</MenuItem>
                            <MenuItem value={"hépatologie"}>hépatologie</MenuItem>
                            <MenuItem value={"infectiologie"}>infectiologie</MenuItem>
                            <MenuItem value={"médecine aiguë"}>médecine aiguë</MenuItem>
                            <MenuItem value={"médecine du travail"}>médecine du travail</MenuItem>
                            <MenuItem value={"médecine générale"}>médecine générale</MenuItem>
                            <MenuItem value={"médecine interne"}>médecine interne</MenuItem>
                            <MenuItem value={"médecine nucléaire"}>médecine nucléaire</MenuItem>
                            <MenuItem value={"médecine palliative"}>médecine palliative</MenuItem>
                            <MenuItem value={"médecine physique"}>médecine physique</MenuItem>
                            <MenuItem value={"médecine préventive"}>médecine préventive</MenuItem>
                            <MenuItem value={"néonatologie"}>néonatologie</MenuItem>
                            <MenuItem value={"néphrologie"}>néphrologie</MenuItem>
                            <MenuItem value={"neurologie"}>neurologie</MenuItem>
                            <MenuItem value={"odontologie"}>odontologie</MenuItem>
                            <MenuItem value={"oncologie"}>oncologie</MenuItem>
                            <MenuItem value={"obstétrique"}>obstétrique</MenuItem>
                            <MenuItem value={"ophtalmologie"}>ophtalmologie</MenuItem>
                            <MenuItem value={"orthopédie"}>orthopédie</MenuItem>
                            <MenuItem value={"ORL"}>ORL</MenuItem>
                            <MenuItem value={"pédiatrie"}>pédiatrie</MenuItem>
                            <MenuItem value={"pneumologie"}>pneumologie</MenuItem>
                            <MenuItem value={"psychiatrie"}>psychiatrie</MenuItem>
                            <MenuItem value={"radiologie"}>radiologie</MenuItem>
                            <MenuItem value={"radiothérapie"}>radiothérapie</MenuItem>
                            <MenuItem value={"rhumatologie"}>rhumatologie</MenuItem>
                            <MenuItem value={"urologie"}>urologie</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      : null }
                      <Grid item xs={12} md={6}>
                        <MKInput success type="text" value={posteEtablissement} onChange={(e)=>{setPosteEtablissement(e.target.value)}} label={<span >Votre poste au sein de l'établissement</span>} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput value={telephone} onChange={(e)=>{setTelephone(e.target.value)}} success type="tel" label={<span >Numéro de téléphone</span>} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput value={email} onChange={(e)=>{setEmail(e.target.value)}} success type="email" label={<span >E-mail</span>} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6} >
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-checkbox-label">Vous recherchez</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={talentChercher}
                            onChange={handleChangeTalentChercher}
                            input={<OutlinedInput label="Vous recherchez" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            style={{height:"42px"}}
                          >
                            {metiers.map((metier) => (
                              <MenuItem key={metier} value={metier}>
                                <Checkbox checked={talentChercher.indexOf(metier) > -1} />
                                <ListItemText primary={metier} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} >
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-checkbox-label">Type de contrat recherché</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={typeContratChercher}
                            onChange={handleChangeTypeContratChercher}
                            input={<OutlinedInput label="Type de contrat recherché" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            style={{height:"42px"}}
                          >
                            {type_contrat.map((contrat) => (
                              <MenuItem key={contrat} value={contrat}>
                                <Checkbox checked={typeContratChercher.indexOf(contrat) > -1} />
                                <ListItemText primary={contrat} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {typeContratChercher.includes("CDD") || typeContratChercher === "CDI" ? (
                        <>
                          <Grid item xs={12} md={6} >
                            <InputLabel id="demo-multiple-checkbox-label">Date de début de contrat</InputLabel>
                            <MKDatePicker label="Date de début de contrat" value={dateDebutCDD} onChange={(e)=>{console.log(e);setDateDebutCDD(e[0])}} input={{ placeholder: "Entrez une date" }} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MKInput value={dureeContrat} onChange={(e)=>{setDureeContrat(e.target.value)}} type="text" style={{marginTop:"10px"}} success label={<span >Durée du contrat</span>} fullWidth />
                          </Grid>
                      </>
                      ): null}

                      
                      <Grid item xs={12}>
                        <MKInput
                          success
                          label="Message"
                          multiline
                          fullWidth
                          rows={6}
                          value={message} onChange={(e)=>{setMessage(e.target.value)}}
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={6}>
                          <MuiFileInput value={file} onChange={handleChangeFile} label="Chargez votre CV"/>
                      </Grid> */}
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={6}>
                      <MKButton onClick={()=>{onSubmit(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,checkEtudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message)}}  variant="contained" color="dark">
                        Envoyer
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
              </AppBar>
          </Container>
          </Container>
        
          </div>
        </Card>
        <DefaultFooter content={footerRoutes} />
      </div>
    </>
  );
}

export default HelpCenter;
