/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import { useMediaQuery } from 'react-responsive'

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// HelpCenter page sections
import SocialAnalytics from "pages/Support/HelpCenter/sections/SocialAnalytics";
import Faq from "pages/Support/HelpCenter/sections/Faq";
import Features from "pages/Support/HelpCenter/sections/Features";
import Contact from "pages/Support/HelpCenter/sections/Contact";
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Form from "components/FormContact";
// Images
import bgImage from "assets/images/bg3.jpg";

import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";


// Otis Kit PRO examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Images
import bgImage1 from "assets/images/examples/color1.jpg";
import bgImage2 from "assets/images/examples/color3.jpg";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import SignInSimple from "./Simple"

function HelpCenter() {
  const [collapse, setCollapse] = useState(false);
  const headerRef = useRef(null);
  const refContact = useRef(null);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 824px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const handleClick = (typeForm) => {
    refContact.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.sorya.eu",
          label: "Connexion",
          color: "info",
        }}
        sticky
      />
      <div style={{ backgroundColor: "#EDFAF8"}}>
        <SignInSimple />
        <DefaultFooter content={footerRoutes} />
      </div>
    </>
  );
}

export default HelpCenter;
