/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import { useMediaQuery } from 'react-responsive'

import * as React from 'react';
import { MuiFileInput } from 'mui-file-input'
import MKDatePicker from "components/MKDatePicker";
import Weekdays from "./components/weekdays";
// Material Kit 2 PRO React components
import MKBadge from "components/MKBadge";
import Stack from "@mui/material/Stack";
import MKButton from "components/MKButton";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//import MKSocialButton from "components/MKSocialButton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import OutlinedInput from '@mui/material/OutlinedInput';
import { contact, get_annonce } from "back_airtable";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// HelpCenter page sections
import SocialAnalytics from "pages/Support/HelpCenter/sections/SocialAnalytics";
import Faq from "pages/Support/HelpCenter/sections/Faq";
import Features from "pages/Support/HelpCenter/sections/Features";
import Contact from "pages/Support/HelpCenter/sections/Contact";
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Form from "components/FormContact";
// Images
import bgImage from "assets/images/bg3.jpg";

import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";


// Otis Kit PRO examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Images
import bgImage1 from "assets/images/examples/color1.jpg";
import bgImage2 from "assets/images/examples/color3.jpg";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Images
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const metiers = [
  'Médecin',
  'Kinésithérapeuthe',
  'Infirmière',
  'Aide-soignante',
  'ASH',
  'ASL',
  'Autre',
];
const type_contrat = [
  'Vacation',
  'CDD',
  'CDI',
];

function HelpCenter() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 824px)'
  })
  const [email, setEmail] = React.useState('');
  const [formulaire, setFormulaire] = React.useState('Professionnel de santé');
  const [nom, setNom] = React.useState('');
  const [prenom, setPrenom] = React.useState('');
  const [nomEtablissement, setNomEtablissement] = React.useState('');
  const [telephone, setTelephone] = React.useState('');
  const [dateDebutCDD, setDateDebutCDD] = React.useState(new Date());
  const [codePostal, setCodePostal] = React.useState('');
  const [posteEtablissement, setPosteEtablissement] = React.useState("");
  const [message,  ] = React.useState('');
  const [typeContratChercher, setTypeContratChercher] = React.useState([]);
  const [talentChercher, setTalentChercher] = React.useState([]);
  const [specialiteCabinet, setSpecialiteCabinet] = React.useState([]);
  const [typeEtablissement, setTypeEtablissement] = React.useState("");
  const [annoncesF, setAnnoncesF] = React.useState([]);
  const [columnA, setColumnA] = React.useState([]);
  const [columnB, setColumnB] = React.useState([]);

  const [cursus, setCursus] = React.useState("");
  const [departement, setDepartement] = React.useState('');
  const [annee, setAnnee] = React.useState("");
  const [dureeContrat, setDureeContrat] = React.useState("");
  const [checkEtudiant, setCheckEtudiant] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState("");
  const [collapse, setCollapse] = useState(false);
  const headerRef = useRef(null);
  const refContact = useRef(null);
  const handleClick = (typeForm) => {
    refContact.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const asyncFunction = async () => {
      const a = await get_annonce();
      setAnnoncesF(a)
      const halfwayPoint = Math.ceil(a.length / 2)
      setColumnA(a.splice(0, a.length / 2))
      setColumnB(a.splice(a.length / 2, a.length))
    }
    asyncFunction();
  }, []);

  const handleChangeTalentChercher = (event) => {
    const {
      target: { value },
    } = event;
    setTalentChercher(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const checkAvailable = (day) => {
    let indic = 0;
    for(let i = 0; i < item.jours.length; i++){
      if(item.jours[i] === day){
        indic += 1;
      }
    }
    if(indic > 0){
      return true;
    }else{
      return false;
    }
  }
  const handleChange = (event) => {
    setCursus(event.target.value);
  };
  const handleChangeTypeEtablissement = (event) => {
    setTypeEtablissement(event.target.value);
  };
  const handleChangeTypeContratChercher = (event) => {
    setTypeContratChercher(event.target.value);
  };
  const handleChangeFile = (newFile) => {
    setFile(newFile)
  }
  const handleChangeDepartement = (event) => {
    setDepartement(event.target.value);
  };
  const handleChangeSpecialiteCabinet = (event) => {
    setSpecialiteCabinet(event.target.value);
  };
  const handleChangeAnnee = (event) => {
    setAnnee(event.target.value);
  };
  const handleChangeCheck = (event) => {
    if(checkEtudiant){
      setCheckEtudiant(false);
    }else{
      setCheckEtudiant(true);
    } 
  };
  const onSubmit = (email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message) => {
    
    /* console.log(typeEtablissement, typeof typeEtablissement)
    console.log(etab,etab.etab)
    console.log(typeForm) */
    //console.log(talentChercher[0] == "" ? ["Non renseigné"] : talentChercher)
    //contact(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message);
    if(activeTab == 0 ){
      setFormulaire("Professionnel de santé")
      if(prenom == "" || nom == "" || email == "" || telephone == "" || cursus == "" || departement == ""){
        setError("Veuillez remplir tous les champs obligatoires")
      }else{
        setError("");
        contact(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message);
        toggleSnackbar();
        setError("Votre message a bien été envoyé")
        reset_state();
      }
    }else if(activeTab == 1 ){
      setFormulaire("Etablissement")
      if(prenom == "" || nom == "" || email == "" || telephone == "" || typeEtablissement == "" ){
        setError("Veuillez remplir tous les champs obligatoirels")
      }else{
        setError("");
        contact(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message);
        toggleSnackbar();
        setError("Votre message a bien été envoyé")
        reset_state()
      }
    }
        
  }

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.sorya.eu",
          label: "Connexion",
          color: "info",
        }}
        sticky
      />
      <div style={{ backgroundColor: "#EDFAF8" }}>
        <MKBox
          minHeight={isDesktopOrLaptop ?"55vh":"75vh"}
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.4),
                rgba(gradients.dark.state, 0.4)
              )}, url("https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695764995/Untitled_18_eao55p.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="whiteTitle">Trouver une annonce</span>
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="whiteTitle">Pour publier une annonce, vous pouvez remplir le formulaire ci-dessous et vous faire rappeler par l’un de nos recruteurs.</span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "#EBF9F1",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
          bgColor="#0F2022"
        >
           
          {/* Nous contacter */}
          <div style={{marginTop:"80px"}} ref={refContact}>
            {/* <Form typeForm={1} etab={"EHPAD"}/> */}
          <Container>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"center",width:"100%"}}>
              <div className="columnA" style={{width:"80%"}}> 
                {columnA.map((item, i) => {
                  return(
                    <div className="container2">
                    <Grid
                      container
                      item
                      justifyContent="center"

                      mx="auto"
                      mb={{ xs: 0, md: 6 }}
                      textAlign="center"
                    >
                      
                    
                      <div className="blockAnnonce" style={{backgroundColor:"#FBFBFB",borderRadius:"10px",width:"80%",padding:"15px 20px 15px 20px"}} onClick={async()=>{console.log(annoncesF)}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                          <div style={{backgroundColor:"#BEECD5",borderRadius:100,padding:10,height:36,width:36,display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1693571813/icons8-loupe-48_xciugs.png"} style={{backgroundColor:"#BEECD5",borderRadius:100,width:20,height:20}} />
                          </div>
                          <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                            <MKTypography  variant="body1" style={{marginLeft:"15px"}}>
                              {item.message}
                            </MKTypography>
                            <MKTypography variant="body2" style={{marginTop:"-5px",marginLeft:"15px"}}>Débute le 01/02/2023{/* {convertDate(datedepart)} */}</MKTypography>
                          </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",marginTop:"10px"}}>
                          <div style={{display:"flex",flexDirection:"row",alignItems:"flex-start"}}>
                            <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687947052/icons8-ba%CC%82timent-48_ze61g1.png"} style={{width: 16,height: 16,marginLeft:11}} />
                            <MKTypography variant="body2" style={{marginTop:"-4px",marginLeft:"7px"}}>{item.nomEtablissement}</MKTypography>
                          </div>
                          <div style={{display:"flex",flexDirection:"row",alignItems:"flex-start",marginTop:"3px"}}>
                            <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1688831211/icons8-marqueur-48_qxl0qn.png"} style={{width: 16,height: 16,marginLeft:11}} />
                            <MKTypography variant="body2" style={{marginTop:"-4px",marginLeft:"7px"}}>
                              {item.codePostal}
                            </MKTypography>
                          </div>
                          <div style={{display:"flex",flexDirection:"row",alignItems:"flex-start" ,marginTop:"3px"}}>
                              <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1688831215/icons8-calendrier-48_mmsboc.png"} style={{width: 16,height: 16,marginLeft:11}} />
                              <div>
                                
                                <MKTypography variant="body2" style={{marginTop:"-4px",marginLeft:"7px"}}>
                                frequence - duree
                                </MKTypography>
                                  
                              </div>
                          </div>
                            <div style={{marginLeft:3,marginRight:0,marginTop:2}}>
                              <Weekdays style={{marginLeft:7,marginRight:10,marginTop:15}} data={[
                                {
                                  title: "Lun",
                                  isActive: false,
                                },
                                {
                                  title: "Mar",
                                  isActive: true,
                                },
                                {
                                  title: "Mer",
                                  isActive: false,
                                },
                                {
                                  title: "Jeu",
                                  isActive:true,
                                },
                                {
                                  title: "Ven",
                                  isActive: true,
                                },
                                {
                                  title: "Sam",
                                  isActive: true,
                                },
                                {
                                  title: "Dim",
                                  isActive: true,
                                },
                              ]} /> 
                            </div>
                            <div>

                            <div
                              style={
                                {
                                  backgroundColor: "#3B3B3B",
                                  width: "30%",
                                  borderRadius: 5,
                                  marginLeft:"12px",
                                  marginTop:"15px",
                                }
                              }
                            >
                              {/* tarif ? */}
                              {true ?
                              <MKTypography variant="body2" style={{color:"#fff"}}>
                              tarif€/periode
                              </MKTypography>
                              :
                              <MKTypography variant="body2" style={{color:"#fff"}}>
                                n.a
                              </MKTypography>
                              }
                            </div> 
              
                          </div>
                        </div>
                      </div>
                    </Grid>
                    </div>
                  )
                })}
              </div> 
              <div className="columnB" style={{width:"80%"}}>
                {columnB.map((item, i) => {
                    return(
                      <div className="container2">
                      <Grid
                        container
                        item
                        justifyContent="center"
                        mx="auto"
                        mb={{ xs: 0, md: 6 }}
                        textAlign="center"
                      >
                        
                      
                        <div className="blockAnnonce" style={{backgroundColor:"#FBFBFB",borderRadius:"10px",width:"80%",padding:"15px 20px 15px 20px"}} onClick={async()=>{console.log(annoncesF)}}>
                          <div style={{display:"flex",flexDirection:"row"}}>
                            <div style={{backgroundColor:"#BEECD5",borderRadius:100,padding:10,height:36,width:36,display:"flex",justifyContent:"center",alignItems:"center"}}>
                              <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1693571813/icons8-loupe-48_xciugs.png"} style={{backgroundColor:"#BEECD5",borderRadius:100,width:20,height:20}} />
                            </div>
                            <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                              <MKTypography  variant="body1" style={{marginLeft:"15px"}}>
                                {item.message}
                              </MKTypography>
                              <MKTypography variant="body2" style={{marginTop:"-5px",marginLeft:"15px"}}>Débute le 01/02/2023{/* {convertDate(datedepart)} */}</MKTypography>
                            </div>
                          </div>
                          <div style={{display:"flex",flexDirection:"column",marginTop:"10px"}}>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"flex-start"}}>
                              <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687947052/icons8-ba%CC%82timent-48_ze61g1.png"} style={{width: 16,height: 16,marginLeft:11}} />
                              <MKTypography variant="body2" style={{marginTop:"-4px",marginLeft:"7px"}}>{item.nomEtablissement}</MKTypography>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"flex-start",marginTop:"3px"}}>
                              <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1688831211/icons8-marqueur-48_qxl0qn.png"} style={{width: 16,height: 16,marginLeft:11}} />
                              <MKTypography variant="body2" style={{marginTop:"-4px",marginLeft:"7px"}}>
                                {item.codePostal}
                              </MKTypography>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",alignItems:"flex-start" ,marginTop:"3px"}}>
                                <img src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1688831215/icons8-calendrier-48_mmsboc.png"} style={{width: 16,height: 16,marginLeft:11}} />
                                <div>
                                  
                                  <MKTypography variant="body2" style={{marginTop:"-4px",marginLeft:"7px"}}>
                                  frequence - duree
                                  </MKTypography>
                                    
                                </div>
                            </div>
                              <div style={{marginLeft:3,marginRight:0,marginTop:2}}>
                                <Weekdays style={{marginLeft:7,marginRight:10,marginTop:15}} data={[
                                  {
                                    title: "Lun",
                                    isActive: false,
                                  },
                                  {
                                    title: "Mar",
                                    isActive: true,
                                  },
                                  {
                                    title: "Mer",
                                    isActive: false,
                                  },
                                  {
                                    title: "Jeu",
                                    isActive:true,
                                  },
                                  {
                                    title: "Ven",
                                    isActive: true,
                                  },
                                  {
                                    title: "Sam",
                                    isActive: true,
                                  },
                                  {
                                    title: "Dim",
                                    isActive: true,
                                  },
                                ]} /> 
                              </div>
                              <div>

                              <div
                                style={
                                  {
                                    backgroundColor: "#3B3B3B",
                                    width: "30%",
                                    borderRadius: 5,
                                    marginLeft:"12px",
                                    marginTop:"15px",
                                  }
                                }
                              >
                                {/* tarif ? */}
                                {true ?
                                <MKTypography variant="body2" style={{color:"#fff"}}>
                                tarif€/periode
                                </MKTypography>
                                :
                                <MKTypography variant="body2" style={{color:"#fff"}}>
                                  n.a
                                </MKTypography>
                                }
                              </div> 
                
                            </div>
                          </div>
                        </div>
                      </Grid>
                      </div>
                    )
                  })}
              </div>
            </div>
          <Container>
         
              <AppBar position="static">
                {/* <Tabs value={activeTab} onChange={handleTabType} style={{backgroundColor:"#DBE5E2"}}>
                  <Tab label="Professionnel de santé" />
                  <Tab label="Etablissement" />
                </Tabs> */}
                <Grid container item xs={12} lg={8} sx={{ mx: "auto" }} py={3}>
              </Grid>
              </AppBar>
          </Container>
          </Container>
        
          </div>
        </Card>
        <DefaultFooter content={footerRoutes} />
      </div>
    </>
  );
}

export default HelpCenter;
