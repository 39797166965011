// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";
import MKButton from "components/MKButton";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
 
//import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import MKInput from "components/MKInput";
/* import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Information from "pages/presentation/sections/Information";
import DesignBlocks from "pages/presentation/sections/DesignBlocks";
import AuthPages from "pages/presentation/sections/AuthPages";
import Pages from "pages/presentation/sections/Pages";
import Testimonials from "pages/presentation/sections/Testimonials";
import Pricing from "pages/presentation/sections/Pricing";

// Presentation page components
import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers"; */

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import styles from "../presentation/mystyle.module.css";
import "../presentation/style.css";

function Aide() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "Connexion",
          color: "info",
        }}
        sticky
      />

  <MKBox
          component="section"
          display="grid"
          position="relative"
      
          
          /* ref={headerRefSec1} */
          width="100%"
        >
          <MKBox component="section" py={6} mt={6} style={{  marginTop:"100px" }}>
            <Container>
              <Grid container spacing={3} item xs={12} lg={8} mx="auto">
                <MKTypography variant="h3" mb={3}>
                  <span className="fontKionaGold">Conditions Générales d'Utilisitation</span>
                </MKTypography>
                <MKTypography variant="body1" textAlign="justify">
                  <span className="fontParaPetit">
                 
Introduction
•	Sorya est un service de placement pour personnes qualifiées, placées dans des fonctions médicales, paramédicales ou d’aides auprès d’établissements de santé et médico-sociaux, de médecins libéraux et de pharmacies d’officines (ci-après « la ou les Structure(s) » ; ci-après « le Service »). Le Service est proposé à des candidats via une application fonctionnant sur smartphone, tablette et ordinateur, et sur site web (ci-après « la Plateforme »).
<br /> <br />
•	Pour bénéficier d’un placement, les candidats doivent remplir l’ensemble des champs obligatoires d’informations les concernant sur la Plateforme. Les informations sollicitées par la Plateforme sont requises par les Structures, notamment compte tenu de la législation et de la réglementation en vigueur, ainsi que de l’activité particulière des Structures, pour accueillir du personnel mis à disposition.
Le candidat déclare que ces informations le concernent personnellement, sont réelles et exactes, que les documents qui accompagnent ces informations sont des copies d’originaux, eux-mêmes réels et exacts. En cas de changement d’une des informations communiquées par le candidat, celui-ci s’engage sans délai à en informer immédiatement Sorya via la Plateforme et à procéder aux changements d’informations et, le cas échéant, des documents associés.
<br /> <br />
•	Pour accéder au Service, le candidat créé sur la Plateforme un profil qui lui est personnel en renseignant une adresse électronique dont la réalité sera contrôlée par l’envoe d’un mot de passe temporaire. Le candidat doit ensuite faire le choix d’un mot de passe définitif alphanumérique à douze caractères comportant au moins un caractère spécial et une majuscule. Le mot de passe est personnel, strictement confidentiel et réservé au seul usage du candidat. Le candidat en est le gardien. Il ne doit aucunement le transmettre ou le communiquer d’une quelconque façon à un tiers. En cas d’anomalie laissant penser au candidat qu’un tiers s’introduit dans son profil, celui-ci doit en informer par écrit et sans délai Sorya.
<br /> <br />
•	Une fois le profil créé, le candidat doit renseigner le calendrier de ses disponibilités. Dès l’instant où ses disponibilités ont été saisies, le candidat peut se voir proposer des offres par les Structures ou peut postuler pour répondre à une offre sur la Plateforme.
<br /> <br />
•	Dès l’instant où une offre est faite au candidat, celui-ci à l’obligation d’y répondre en temps utile. Dès l’instant où il a connaissance d’une offre, le candidat s’engage à ne pas solliciter la Structure responsable de cette offre en dehors du Service et de Sorya. Il s’engage également à ne pas répondre à la sollicitation d’une Structure en dehors du Service et de Sorya dès l’instant où il a répondu à une offre de ladite Structure sur la Plateforme.
<br /> <br />
•	Le Candidat, une fois sélectionné par une Structure, s’engage à agir de bonne foi tant à l’égard de Sorya et du Service que dans sa relation avec la Structure.
<br /> <br />
•	En cas d’inactivité pendant un délai continu de douze mois à compter de la dernière connexion à la Plateforme, Sorya se réserve la faculté de procéder sans autre délai ni préavis à la suspension du profil du Candidat inactif.
<br /> <br />
•	En cas de violation d’une des dispositions des présentes CGU, Sorya a la faculté de suspendre le profil du candidat, sans préavis et sans délai en cas de faute grave, après avoir notifié la faculté de suspension par écrit et par tout moyen en donnant la possibilité de s’expliquer par retour dans un délai de huit jours, sans préjudice de tous dommages et intérêts qui pourraient être sollicités par Sorya si le manquement lui cause un préjudice.
<br /> <br />
•	Tout enregistrement sur la plateforme fait foi entre les partis et vaut preuve notamment en cas de litige.
<br /> <br />
•	Sorya est l’éditeur du Service. Sorya est une société par actions simplifiées en cours d’immatriculation auprès du Registre du Commerce et des Sociétés de Paris, dont le siège social est situé à Paris … Le réprésentant de Sorya est le directeur de publication du service. Le service est hébergé par MangoDB.
                   {/*  <br /> <br />
                    Notre vision est de contribuer à l&apos;émergence d&apos;un monde où les
                    entreprises, les consommateurs et la société dans son ensemble peuvent prospérer
                    ensemble. Nous pensons qu&apos;il est possible de créer des solutions qui
                    offrent des avantages pour toutes les parties prenantes. En effet, nous sommes
                    convaincus que les entreprises peuvent réaliser des profits tout en ayant un
                    impact positif sur la société.
                    <br /> <br />
                    Notre équipe partage cette vision commune et travaille pour trouver des
                    solutions innovantes pour nos clients. Nous sommes enthousiastes à l&apos;idée
                    de partager notre vision et de collaborer avec vous. */}
                  </span>
                </MKTypography>
              </Grid>
            </Container>
          </MKBox>

  </MKBox>



       

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Aide;
