// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";
import MKButton from "components/MKButton";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
 
//import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import MKInput from "components/MKInput";
/* import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Information from "pages/presentation/sections/Information";
import DesignBlocks from "pages/presentation/sections/DesignBlocks";
import AuthPages from "pages/presentation/sections/AuthPages";
import Pages from "pages/presentation/sections/Pages";
import Testimonials from "pages/presentation/sections/Testimonials";
import Pricing from "pages/presentation/sections/Pricing";

// Presentation page components
import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers"; */

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import styles from "../presentation/mystyle.module.css";
import "../presentation/style.css";

function Aide() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "Connexion",
          color: "info",
        }}
        sticky
      />

  <MKBox
          component="section"
          display="grid"
          position="relative"
      
          
          /* ref={headerRefSec1} */
          width="100%"
        >
          <MKBox component="section" py={6} mt={6} style={{  marginTop:"100px" }}>
            <Container>
              <Grid container spacing={3} item xs={12} lg={8} mx="auto">
                <MKTypography variant="h3" mb={3}>
                  <span className="fontKionaGold">POLITIQUE DE CONFIDENTIALITE DE DONNÉES PERSONNELLES </span>
                </MKTypography>
                <MKTypography variant="body1" textAlign="justify">
                  <span className="fontParaPetit">
                 

                  Le présent document vient en complément des Conditions Générales d’Utilisations https://sorya.eu/confidentialite (CGU) de Sorya. Les mots définis aux CGU sont repris au sein du présent document qui concerne le Service, c’est-à-dire l’application éditée par Sorya.
<br /> <br />
Sorya fait de la protection de vos données à caractère personnel une de ses priorités. Vous avez consenti aux CGU du Service, en tant que candidat (ci-après « Vous » ou le « candidat »). Dans le cadre de la gestion commerciale de ses candidats, ou de l’utilisation du Service, des données à caractère personnel sont susceptibles d’être traitées par Sorya, en sa qualité de « Responsable de traitement » ou éventuellement de « Sous-traitant », au sens des articles 4 (7) et 4 (8) du RGPD.
<br /> <br />
À ce titre, Sorya s’engage à respecter les données à caractère personnel Vous concernant, ou que Vous lui confierez, qui sont collectées et traitées à l'occasion de votre utilisation du Service. À cet égard, Sorya respecte les législations européennes et françaises en matière de protection des données personnelles, principalement le règlement européen UE n°2016/679 dit « RGPD » et la loi n°78-17 dite « Informatique et Libertés ».
<br /> <br />
Le délégué à la protection des données (DPO) de Sorya peut être contacté à l’adresse suivante : dpo@sorya.fr ou à l’adresse de son siège social tel qu’immatriculé au Registre du Commerce et de Sociétés près des Tribunaux de commerce. 
<br /> <br />
<MKTypography variant="h4" mb={3}>
  <span className="fontKionaGold">Finalités des traitements et base légale :</span>
</MKTypography>
<MKTypography variant="h6" mb={3}>
  <span className="fontKionaGold">Gestion de la relation commerciale – Sorya est Responsable de traitement </span>
</MKTypography>
Sorya collecte et traite Vos données à caractère pour les finalités suivantes :<br /> <br />
La présente Politique de Données Personnelles répond aux exigences de transparence posées par les articles 12 et suivants du RGPD, et présente les différents traitements de données à caractère personnel en lien avec l’utilisation du Service. 
<br /> 
- la gestion de sa relation administrative et commerciale entre Vous, Sorya et le Professionnel de Santé ;
<br />
- l’assistance et le support dans l’utilisation du Service.
<br />
Pour les finalités précitées, Sorya est Responsable de traitement
<br /> 
Conformément à l’article 6-1 (b) du RGPD, ce traitement est nécessaire à l’exécution du Contrat conclu entre Vous et Sorya dans le cadre de l’utilisation du Service.
<br /> <br />
<MKTypography variant="h6" mb={3}>
  <span className="fontKionaGold">Destinataires des données</span>
</MKTypography>
Les données collectées sur le Service sont exclusivement destinées à Sorya, et/ou aux sous-traitants auxquels il est éventuellement fait appel dans le cadre de la gestion du Service, ainsi qu’aux Professionnels de Santé à toutes administrations, organisations dont l’activité est nécessaire pour assurer le placement du candidat au moyen du Service.
<br /><br />
Les sous-traitants auxquels recourt Sorya pour la bonne exploitation du Service, sont :
<br />
EXEMPLE AWS, dont le siège social se situe à .P.O. Box 81226 Seattle, WA 98108-1226, Etats-Unis, hébergeur, dont les serveurs se trouvent en France
(Pour plus d’information sur le traitement des données à caractère personnel par AWS : https://aws.amazon.com/fr/compliance/data-privacy-faq/)
<br /><br />
Le candidat est responsable de la confidentialité de données à caractère personnel qu’il a transmis à Sorya par application des CGU [LIEN], notamment s’agissant de l’authentification à l’accès de la Plateforme, le candidat étant seul gardien du mot de passe qu’il a choisi pour y accéder, et de son caractère confidentiel.
<br /><br />
<MKTypography variant="h6" mb={3}>
  <span className="fontKionaGold">Durée de conservation </span>
</MKTypography>
Les données du candidat sont conservées pendant la durée du profil et du compte ouvert sur la Plateforme, et le cas échéant pendant le temps nécessaire à la gestion de la relation sur un plan administratif, comptable et fiscal. Les données des candidats utilisées à des fins de prospection commerciale peuvent être conservées pendant un délai de trois ans à compter de la fin de la relation commerciale.  
<br /><br />
Toutes les données permettant d’établir la preuve d’un droit ou d’un contrat, ou conservées au titre du respect d’une obligation légale et/ou règlementaire, peuvent faire l’objet d’une politique d’archivage, et être conservées à ces fins conformément aux dispositions légales et/ou règlementaires en vigueur.
<br /><br />
<MKTypography variant="h6" mb={3}>
  <span className="fontKionaGold">Mesures de sécurité</span>
</MKTypography>

Sorya s’engage à assurer la confidentialité des données collectées, et à mettre en œuvre toutes les mesures techniques et organisationnelles adaptées pour préserver leur sécurité et leur intégrité, notamment contre la perte accidentelle, l’altération, la diffusion ou l’accès non autorisé.
<br />
Sorya met notamment en place les mesures de protection suivante :
<br />
Une authentification renforcée avec un mot de passe alphanumérique de 12 caractères minimum avec une majuscule et un caractère spécial ;
<br />
La journalisation des activités observées sur la Plateforme et le profil du candidat ;
<br />
Le recours au chiffrement ;
<br />
Le recours à la pseudonymisation;
<br /><br/>
<MKTypography variant="h6" mb={3}>
  <span className="fontKionaGold">Vos droits</span>
</MKTypography>
Conformément à la loi n°78-17 du 6 janvier 1978 et au RGPD, Vous disposez d'un droit d'accès, de rectification ou d'effacement des données à caractère personnel vous concernant, et le cas échéant, d'un droit à la portabilité de vos données. Vous pouvez demander la limitation ou Vous opposer au traitement de vos données, ou le cas échéant, retirer votre consentement. Vous pouvez également définir des directives sur le sort de vos données après votre mort, par application de l'article 32 6° de la Loi modifiée n°78-17 du 6 Janvier 1978.
<br></br>
Pour exercer ces droits, Vous pouvez vous adresser à Sorya en écrivant au Délégué à la protection des données (DPO) à l’adresse électronique suivante : dpo@sorya.fr ou encore à l'adresse du siège social de Sorya.
<br></br>
Conformément à la loi, Vous disposez du droit légal d'introduire toute réclamation auprès d'une autorité de contrôle : https://www.cnil.fr/ 
<br></br>
          </span>
        </MKTypography>
      </Grid>
    </Container>
  </MKBox>

  </MKBox>



       

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Aide;
