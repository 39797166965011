/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import { useMediaQuery } from 'react-responsive'

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// HelpCenter page sections
import SocialAnalytics from "pages/Support/HelpCenter/sections/SocialAnalytics";
import Faq from "pages/Support/HelpCenter/sections/Faq";
import Features from "pages/Support/HelpCenter/sections/Features";
import Contact from "pages/Support/HelpCenter/sections/Contact";
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Form from "components/FormContact";
// Images
import bgImage from "assets/images/bg3.jpg";

import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";


// Otis Kit PRO examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Images
import bgImage1 from "assets/images/examples/color1.jpg";
import bgImage2 from "assets/images/examples/color3.jpg";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function HelpCenter() {
  const [collapse, setCollapse] = useState(false);
  const headerRef = useRef(null);
  const refContact = useRef(null);
  const handleClick = (typeForm) => {
    refContact.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 824px)'
  })
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.sorya.eu",
          label: "Connexion",
          color: "info",
        }}
        sticky
      />
      <div style={{ backgroundColor: "#DDE8E5" }}>
        <MKBox
          minHeight={isDesktopOrLaptop ?"55vh":"70vh"}
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.4),
                rgba(gradients.dark.state, 0.4)
              )}, url("https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694527996/Sans_titre_pixdsc.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="whiteTitle">Hôpital & Clinique</span>
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="whiteTitle">La plateforme qui vous connecte en un clin d’oeil aux professionnels de santé autour de vous</span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "#EBF9F0",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
          bgColor="#0F2022"
        >
          
         
          {/* Trois Axes */}
          {/* <MKBox component="section" py={6} mt={6}>
            <Container>
              <Grid container spacing={3} item xs={12} lg={8} mx="auto">
                <div style={{flexDirection:"column"}}>
                <MKTypography component="h5" opacity={0.9}>
                  <span className="fontThin">sorya.</span>
                  
                </MKTypography>
                <MKTypography variant="h3" mb={3}>
                  <span className="fontKionaGold">La plateforme qui vous connecte en un clic aux professionnels de santé autour de vous.</span>
                </MKTypography>
                </div>
                <MKTypography variant="body1" textAlign="justify">
                  <span className="fontParaPetit">
                    Nous sommes une équipe de professionnels compétents et passionnés. Ce qui nous
                    distingue, c&apos;est notre vision pour l&apos;avenir. Nous sommes animés par la
                    conviction que nous pouvons créer des solutions innovantes qui permette de
                    mettre en place des cercles vertueux bénéfiques à tous les acteurs impliqués.
                    <br /> <br />
                    Notre vision est de contribuer à l&apos;émergence d&apos;un monde où les
                    entreprises, les consommateurs et la société dans son ensemble peuvent prospérer
                    ensemble. Nous pensons qu&apos;il est possible de créer des solutions qui
                    offrent des avantages pour toutes les parties prenantes. En effet, nous sommes
                    convaincus que les entreprises peuvent réaliser des profits tout en ayant un
                    impact positif sur la société.
                    <br /> <br />
                    Notre équipe partage cette vision commune et travaille pour trouver des
                    solutions innovantes pour nos clients. Nous sommes enthousiastes à l&apos;idée
                    de partager notre vision et de collaborer avec vous.
                  </span>
                </MKTypography>
              </Grid>
            </Container>
          </MKBox> */}
          {/* Nos valeurs */}
          <MKBox component="section" py={8}>
            <Container>
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
                lg={6}
                sx={{ mx: "auto", pb: 3, textAlign: "center" }}
              >
                <MKTypography variant="h2" my={1}>
                  <span className="fontKionaGold">Nos atouts</span>
                </MKTypography>
                <MKTypography variant="h4" color="text">
                  <span className="fontThin">
                  Un large panel de professionnels de santé présélectionnés autour de vous par nos recruteurs
                  </span>
                </MKTypography>
              </Grid>
              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6} lg={4}>
                  <DefaultInfoCard
                    icon="mouse"
                    color="warning"
                    title={<><span className="bigWordThin">Publiez vos annonces</span><br/><span className="fontThin">en quelques clics</span></>}
                    description={
                      <span className="fontParaPetit">
                        Accédez à un large réseau de professionnels en publiant vos annonces.
Notre plateforme intuitive vous permet de publier vos annonces en toute simplicité.

                      </span>
                    }
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DefaultInfoCard
                    icon="schedule"
                    color="warning"
                    title={<><span className="bigWordThin">Gagnez du temps</span><br/><span className="fontThin">sur les formalités administratives</span></>}
                    description={
                      <span className="fontParaPetit">
                        Ne perdez plus de temps à préparer les contrats ni à réunir les pièces des candidats ! 
Vous recevez l’ensemble des pièces et le contrat signé directement sur la plateforme.

                      </span>
                    }
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
                  <DefaultInfoCard
                    icon="insert_chart"
                    color="warning"
                    title={<><span className="bigWordThin">Payez selon vos besoins</span><br/><span className="fontThin">en recrutement</span></>}
                    description={
                      <span className="fontParaPetit" >
                        Publiez autant d’annonces que vous voulez sans frais, et payez uniquement lorsque vous recrutez un candidat.
Optez pour une approche économique, souple et sans contraintes.

                      </span>
                    }
                    direction="center"
                  />
                </Grid>
              </Grid>
              
            </Container>
          </MKBox>
          <MKBox
            variant="gradient"
            bgColor="dark"
            position="relative"
            borderRadius="xl"
            mx={{ xs: isDesktopOrLaptop ? 2 : -1, xl: 16, xxl: 16 }}
            mt={0}
            py={10}
            px={3}
            sx={{ overflow: "hidden",mt: 5  }}
          >
          <MKBox
              component="img"
              src={bgPattern}
              alt="pattern-lines"
              position="absolute"
              top={0}
              left={0}
              bgColor="#0F2022"
              width="100%"
              opacity={0.6}
            />
            <Container sx={{ position: "relative" }}>
        <Grid container spacing={3} alignItems="center">
         
          <Grid
                  item
                  xs={12}
                  md={7}
                  lg={7}
                  py={{ xs: 0, sm: 0 }}
                  mr="auto"
                  position="relative"
                >
                  <MKTypography variant="h2" color="white" mb={3} className="fontThin">
                    <span className="fontKThin">Simplifiez </span><span className="fontKionaGold2"> la gestion de vos planning</span>
                    {/* <span className="fontKionaGold">SIMPLIFIE</span>
                    <span className="fontKThin"> la vie</span> */}

                  </MKTypography>
                  <MKTypography variant="body1" color="white" mb={0}>
                  Sorya est l’agence de recrutement nouvelle génération qui simplifie votre recherche et votre gestion de personnel médical et paramédical.
              Assurez la continuité des soins au sein de votre établissement en vous connectant rapidement avec des professionnels disponibles autour de vous.
                  </MKTypography>
                 
                  {/* <MKTypography variant="body1" color="white" mb={6}>
                    Au contraire, nous nous adaptons aux meilleures solutions de réservation
                    existante.
                  </MKTypography> */}
              
  
                </Grid>
          <Grid container justifyContent="center" alignItems="center" item xs={12} lg={5}  >
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "lights",
                label: "find out more",
              }}
            />
            <MKBox p={3} mt={0} >
              <MKButton onClick={()=>{handleClick(0)}} variant="contained" color="light">
                      Publier une annonce
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
            </Container>
    </MKBox>
           
          {/* FAQ */}
          {/* <MKBox component="section" py={8}>
            <Container>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={6} my={6}>
                  <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
                    <span className="fontKionaGold">Questions fréquentes</span>
                  </MKTypography>
                  <MKBox mb={2}>
                    <MKTypography variant="body1" align="center" color="text">
                      <span className="fontPara">
                        Vous pouvez également nous contacter par email pour d&apos;autres questions:
                        contact@tablaba.com
                      </span>
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FaqCollapse
                    title={<span className="fontBold">Question 1</span>}
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 2</span>}
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 3</span>}
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 4</span>}
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 5</span>}
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                </Grid>
              </Grid>
            </Container>
          </MKBox> */}
          {/* <Features /> */}
          {/* Nous contacter */}
          <div style={{marginTop:"110px"}} ref={refContact}>
          <Form typeForm={1} etab={"Hôpital et clinique"}/>
          </div>
        </Card>
        <DefaultFooter content={footerRoutes} />
      </div>
    </>
  );
}

export default HelpCenter;
