/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for type checking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { backdropClasses } from "@mui/material";

function AboutUsOption({ icon, content ,color}) {
  return (
    <MKBox display="flex" alignItems="center" p={2}>
      <MKBox
        width="3rem"
        height="3rem"
        variant="gradient"
        bgColor='#4BCFBC'
        color='#4BCFBC'
        coloredShadow="dark"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="xl"
        style={{backgroundColor:color}}
      >
        {typeof icon == 'number' ?
        <MKTypography variant="h6" color='light'  >
        <span >{icon}</span>
      </MKTypography>
        :
        <Icon fontSize="small">{icon}</Icon>
        }
      </MKBox>
      <MKTypography  variant="body1" color="text" pl={2} >
        <span className="fontSlide" >{content}</span>
      </MKTypography>
    </MKBox>
  );
}

// Typechecking props for the AboutUsOption
AboutUsOption.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default AboutUsOption;
