/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import SimpleLayout from "pages/Authentication/components/SimpleLayout";
import Separator from "pages/Authentication/components/Separator";
import Socials from "pages/Authentication/components/Socials";

function SignInSimple() {
  const [rememberMe, setRememberMe] = useState(true);
  
  
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <SimpleLayout>
      <Card >
        <MKBox
          //variant="gradient"
          bgColor="#BEECD5"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          pt={2.5}
          pb={2.875}
          px={2.5}
          textAlign="center"
          lineHeight={1}
        >
          <MKTypography variant="h4" fontWeight="medium" mt={1}>
            <span className="sousTitre">Connexion</span>
          </MKTypography>
          <MKTypography variant="button" color="dark">
            Espace établissement
          </MKTypography>
        </MKBox>
        <MKBox p={3} style={{marginTop:"10px"}}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput type="email" label="E-mail" fullWidth />
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="password" label="Mot de passe" fullWidth />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Se souvenir de moi
              </MKTypography>
            </MKBox>
            <MKBox mt={2} mb={1}>
              <MKButton variant="contained" color="info" fullWidth>
                <span className="sousTitre">Se connecter</span>
              </MKButton>
            </MKBox>
            <MKBox mt={2} mb={1}>
              <MKButton 
              component={Link}
              to="/inscription"
              variant="outlined" color="info" fullWidth>
                <span className="sousTitre">S'inscrire</span>
              </MKButton>
            </MKBox>
            <Separator />
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around"}}>
              <img src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695039454/Apple3_deyxdv.gif" alt="logo" style={{ height:"40px"}} />
              <img src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695039855/google-play-badge_lfqylx.png" alt="logo" style={{ height:"40px"}} />
            </div>
           
          </MKBox>
        </MKBox>
      </Card>
    </SimpleLayout>
  );
}

export default SignInSimple;
