import React, { memo } from "react";


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";



const Weekdays = memo(
  ({ data, size = "medium", status = "basic", style }) => {
    const getSize = (
      size
    ) => {
      switch (size) {
        case "giant":
          return 40;
        case "large":
          return 24;
        case "medium":
          return 16;
        default:
          return 24;
      }
    };
    return (
      <div style={{marginTop:"8px",display:"flex",flexDirection:"row"}} >
        {data.map((item, i) => {
          return (
            <MKTypography
            variant="h6"
            center
            uppercase
              key={i}
              style={
                {
                fontSize:12,     
                color: !item.isActive ? "#3B3B3B" : "#3B3B3B" ,   
                display:"flex",     
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                borderRadius: 4,
                marginLeft: 8,
                
                marginRight: 8,     
                width: "20px",
                height: "20px",
                backgroundColor: !item.isActive ? "#BEECD5" : "#D9DDD5",
                }
              }
            >

                {item.title.charAt(0)}
              </MKTypography>
        
          );
        })}
      </div>
    );
  }
);

export default Weekdays;

