// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState ,forwardRef} from "react";
import * as React from 'react';
import { MuiFileInput } from 'mui-file-input'
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import DatePickerWrapper from './DatePickerWrapper'

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";
import MKButton from "components/MKButton";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useMediaQuery } from 'react-responsive'
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//import MKSocialButton from "components/MKSocialButton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from 'react-datepicker'

import FormControl from '@mui/material/FormControl';
// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import MKInput from "components/MKInput";
import OutlinedInput from '@mui/material/OutlinedInput';
import { contact ,  contact_v2} from "back_airtable";
/* import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import ListItemText from '@mui/material/ListItemText';

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Information from "pages/presentation/sections/Information";
import DesignBlocks from "pages/presentation/sections/DesignBlocks";
import AuthPages from "pages/presentation/sections/AuthPages";
import Pages from "pages/presentation/sections/Pages";
import Testimonials from "pages/presentation/sections/Testimonials";
import Pricing from "pages/presentation/sections/Pricing";

// Presentation page components
import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers"; */

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import { Typography } from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const metiers = [
  'Médecin',
  'Kinésithérapeute',
  'Infirmière',
  'Aide-soignante',
  'ASH',
  'ASL',
  'Autre',
];
const type_contrat = [
  'Vacation',
  'CDD',
  'CDI',
];

function Form(typeForm,etab) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 824px)'
  })
  const CustomInput = forwardRef((props, ref) => {
    return <TextField fullWidth {...props} inputRef={ref} label="Date de début" autoComplete='off' />
  })
  const CustomInputFin = forwardRef((props, ref) => {
    return <TextField fullWidth {...props} inputRef={ref} label="Date de fin" autoComplete='off' />
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const [value, setValue] = useState()

  const handleChangeP = (event, newValue ) => {
    console.log(newValue)
    setValue(newValue)
  }
  const duplicate = (obj) => {
    const arrCopy = Object.assign({}, obj)

    return arrCopy
  }
  const [email, setEmail] = React.useState('');
  const [formulaire, setFormulaire] = React.useState('Professionnel de santé');
  const [nom, setNom] = React.useState('');
  const [prenom, setPrenom] = React.useState('');
  const [nomEtablissement, setNomEtablissement] = React.useState('');
  const [telephone, setTelephone] = React.useState('');
  const [codePostal, setCodePostal] = React.useState('');
  const [posteEtablissement, setPosteEtablissement] = React.useState("");
  const [message, setMessage] = React.useState('');
  const [typeContratChercher, setTypeContratChercher] = React.useState([]);
  const [talentChercher, setTalentChercher] = React.useState([]);
  const [typeEtablissement, setTypeEtablissement] = React.useState(typeForm.etab ? typeForm.etab : "");
  const [cursus, setCursus] = React.useState("");
  const [departement, setDepartement] = React.useState('');
  const [annee, setAnnee] = React.useState("");
  const [checkEtudiant, setCheckEtudiant] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState("");
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const toggleSnackbar = () => setShow(!show);
  const toastStyles = ({
    palette: { info },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: info.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,marginTop:"-100px"
    },
  });
  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      Votre demande a été envoyée et est en cours de traitement.
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );
  const [activeTab, setActiveTab] = useState(typeForm.typeForm == 0 ? 0 : 1);

  const handleTabType = (event, newValue) => {setActiveTab(newValue);if(newValue == 0){setFormulaire("Professionnel de santé")}else{setFormulaire("Etablissement")}};

  const handleChangeTalentChercher = (event) => {
    const {
      target: { value },
    } = event;
    setTalentChercher(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    
  };
  const list_annee_etude = ['1,','2','3','4','5','6','interne']
  const list_semestres = ['Semestre 1','Semestre 2','Semestre 3','Semestre 4','Semestre 5','Semestre 6','Semestre 7','Semestre 8','Semestre 9','Semestre 10','Semestre 11','Semestre 12','Semestre 13','Semestre 14']
  const list_para = ['Aide-Soignant(e) (AS)',"Aide-Soignant(e) hospitalier(e) (ASH)","Ambulancier",,"Assistant Dentaire","Audioprothésiste","Chirurgien dentiste","Diététicienne Nutritionniste","Ergothérapeute","Infirmier diplômé d’État (IDE)","Kinésithérapeute","Manipulateur en électroradiologie médicale","Médecin","Opticien lunetier","Orthodontiste","Orthophoniste","Orthoptiste","Ostéopathe","Pédicure Podologue","Pharmacien","Podo-orthésiste","Préparateur en Pharmacie","Psychomotricien","Puériculteur/Puéricultrice","Physiothérapeute","Sage femme","Secrétaire Médical","Technicien/Technicienne d’analyses biomédicales","Thanatopracteur","Autres (champ libre - à préciser)"]
  const list_medecin = ["Allergologie","Anesthésiologie","Andrologie","Cardiologie","Chirurgie Cardiaque","Chirurgie Esthétique, Plastique et Reconstructive","Chirurgie Générale","Chirurgie Maxillo-faciale","Chirurgie Pédiatrique","Chirurgie Thoracique","Chirurgie Vasculaire","Dermatologie","Endocrinologie","Gastro-entérologie","Gériatrie","Gynécologie Médicale","Gynécologie Obstétrique","Hématologie","Hépatologie","Immunologie","Infectiologie","Médecine Aiguë","Médecine du Travail","Médecine Générale","Médecine Nucléaire","Médecine Palliative","Médecine Physique et de Réadaptation (MPR)","Médecine Préventive","Néonatologie","Néphrologie","Neurochirurgie","Neurologie","Oncologie","Ophtalmologie","Orthopédie","ORL" ,"Pédiatrie","Pneumologie","Psychiatrie","Radiologie"]
  const list_chir_dent = ["Chirurgien-dentiste","Chirurgien-dentiste implantologue","Orthodontiste","Parodontiste","Pédodontiste","Occlusodontiste" ,"Prosthodontiste"]
  const list_infirmier = ["Infirmier anesthésiste (IADE)","Infirmier bloc opératoire (IBODE)","Infirmier coordinateur (IDEC)","Infirmier en pratique Avancée (IPA)","Infirmier puériculteur (IPDE)"]
  const list_post_rech = ["Remplacement", "Stage", "Alternance", "Contrat de professionnalisation", "Vacation", "CDI", "CDD", "Garde", "Assistanat", "Job étudiant","Autre"]
  const list_post_rech_pro= [ "Emploi salarié", "Remplacement libéral", "Installation - Local à louer", "Installation - Local à acheter","Autre"]
  const list_type_contrat = [ "Vacation", "CDD", "CDI", "Garde","Collaboration","Remplacement occasionnel","Remplacement régulier","Alternance","Contrat de professionnalisation","Stage","Autre"]
  const list_type_etab = ["Cabinet libéral", "Centre", "Clinique", "Hôpital", "Industrie", "Laboratoire", "Maison de santé", "Pharmacie", "Ehpad", "Téléconsultation", "Maison de santé pluridisciplinaire"]

  const reset_state = () => {
    setEmail("");
    setFormulaire("Professionnel de santé");
    setNom("");
    setPrenom("");
    setNomEtablissement("");
    setTelephone("");
    setCodePostal("");
    setCheckEtudiant(false);
    setCursus("");
    setDepartement("");
    setAnnee("");
    setTypeEtablissement("");
    setPosteEtablissement("");
    setTalentChercher([""]);
    setTypeContratChercher([""]);
    setMessage("");
    setCheckEtudiant(false);
    setFile(null);

    setError("");
  }

  const handleChange = (event) => {
    setCursus(event.target.value);
  };
  const handleChangeTypeEtablissement = (event) => {
    setTypeEtablissement(event.target.value);
  };
  const handleChangeTypeContratChercher = (event) => {
    setTypeContratChercher(event.target.value);
  };
  const handleChangeFile = (newFile) => {
    setFile(newFile)
  }
  const handleChangeDepartement = (event) => {
    setDepartement(event.target.value);
  };
  const handleChangeAnnee = (event) => {
    setAnnee(event.target.value);
  };
  const handleChangeCheck = (event) => {
    if(checkEtudiant){
      setCheckEtudiant(false);
    }else{
      setCheckEtudiant(true);
    } 
  };
  const onSubmit = () => {
    
    /* console.log(typeEtablissement, typeof typeEtablissement)
    console.log(etab,etab.etab)
    console.log(typeForm) */
    //console.log(talentChercher[0] == "" ? ["Non renseigné"] : talentChercher)
    //contact(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message);
    if(activeTab == 0 ){
      setFormulaire("Professionnel de santé")
      /* if(prenom == "" || nom == "" || email == "" || telephone == "" || cursus == "" || departement == ""){
        setError("Veuillez remplir tous les champs obligatoires")
      } */
      if(value && value.nom && value.prenom && value.email){
        setError("");
        contact_v2(value,"Professionnel de santé")        
        toggleSnackbar();
        //setError("Votre message a bien été envoyé")
        setValue('');
        
      }
      else{
        setError("Veuillez remplir tous les champs obligatoires")
        
      }
    }else if(activeTab == 1 ){
      setFormulaire("Etablissement")
      if(value && value.type_contrat_ann ){
        setError("");
        contact_v2(value,"Etablissement")  
        //contact(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,talentChercher,typeContratChercher,message);
        toggleSnackbar();
        reset_state()
        
      }else{
        setError("Veuillez remplir tous les champs obligatoirels")
      }
    }
        
  }
  return (
    <>
      <MKBox component="section" py={4}>
      
        <Container>
          <Grid
            container
            item
            justifyContent="center"
            xs={10}
            lg={7}
            mx="auto"
            mb={{ xs: 0, md: 6 }}
            textAlign="center"
          >
            <MKTypography variant="h1" mb={1}>
              <span className="fontKionaGold">Nous Contacter</span>
            </MKTypography>
            <MKTypography variant="body1" color="text">
              <span className="fontPara">
                Nous tâchons de répondre dans les plus brefs délais à vos demandes.
              </span>
            </MKTypography>
          </Grid>
          <Container>
            {isDesktopOrLaptop ? (
            <Grid container item justifyContent="center" xs={12} lg={4} mx="auto">
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType} style={{backgroundColor:"#DBE5E2"}}>
                <Tab label="Je candidate" />
                <Tab label="Je recrute" />
              </Tabs>
            </AppBar>
          </Grid>
            ) : (
              <MKBox container item justifyContent="center" xs={12} lg={4} 
            
            sx={{ overflow: "hidden",mt: isDesktopOrLaptop ? 0 :4 }}
            mx={{ xs: isDesktopOrLaptop ? 2 : -5, xl: 16, xxl: 16 }}>
              <AppBar position="static">
                <Tabs value={activeTab} onChange={handleTabType} style={{backgroundColor:"#DBE5E2"}}>
                  <Tab label="Je candidate" />
                  <Tab label="Je recrute" />
                </Tabs>
              </AppBar>
            </MKBox>
            )}
          </Container>
          
          {activeTab == 0 ? (
          <Grid container item xs={12} lg={8}  sx={{ mx: "auto" }} py={3}>
            <MKBox width="100%" component="form" method="post" autocomplete="off">
              <MKBox p={3}>
                <Grid container spacing={3}>
               {/*  <Grid item xs={12} md={6}>
                    <MKInput value={prenom} type="text" onChange={(e)=>{setPrenom(e.target.value)}} success label={<span >Prénom*</span>} fullWidth />
                  </Grid> */}
                  <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        value={value && value.prenom ? value.prenom : ''}
                        label='Prénom'
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.prenom = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='textarea-outlined-controlled'
                      />
                     
                    </Grid>
                    <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        value={value && value.nom ? value.nom : ''}
                        label='Nom'
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.nom = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='textarea-outlined-controlled'
                      />
                     
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <TextField
                          fullWidth
                          value={value && value.email ? value.email : ''}
                          label='Email'
                          
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.email = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          id='textarea-outlined-controlled'
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <TextField
                          fullWidth
                          value={value && value.tel ? value.tel : ''}
                          label='Téléphone'
                          
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.tel = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          id='textarea-outlined-controlled'
                        />
                    </Grid>
                  <Grid item xs={12} md={6} >
                  <FormControl fullWidth >
                        <InputLabel id='controlled-select-label'>Département</InputLabel>
                        <Select
                          value={value && value.departement ? value.departement : ''}
                          label='Département'
                          id='controlled-select'
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.departement = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          style={{height:45}}
                          labelId='controlled-select-label'
                        >
                        <MenuItem value={"01 - Ain"}>01 - Ain</MenuItem>
                        <MenuItem value={"02 - Aisne"}>02 - Aisne</MenuItem>
                        <MenuItem value={"03 - Allier"}>03 - Allier</MenuItem>
                        <MenuItem value={"04 - Alpes-de-Haute-Provence"}>04 - Alpes-de-Haute-Provence</MenuItem>
                        <MenuItem value={"05 - Hautes-Alpes"}>05 - Hautes-Alpes</MenuItem>
                        <MenuItem value={"06 - Alpes-Maritimes"}>06 - Alpes-Maritimes</MenuItem>
                        <MenuItem value={"07 - Ardèche"}>07 - Ardèche</MenuItem>
                        <MenuItem value={"08 - Ardennes"}>08 - Ardennes</MenuItem>
                        <MenuItem value={"09 - Ariège"}>09 - Ariège</MenuItem>
                        <MenuItem value={"10 - Aube"}>10 - Aube</MenuItem>
                        <MenuItem value={"11 - Aude"}>11 - Aude</MenuItem>
                        <MenuItem value={"12 - Aveyron"}>12 - Aveyron</MenuItem>
                        <MenuItem value={"13 - Bouches-du-Rhône"}>13 - Bouches-du-Rhône</MenuItem>
                        <MenuItem value={"14 - Calvados"}>14 - Calvados</MenuItem>
                        <MenuItem value={"15 - Cantal"}>15 - Cantal</MenuItem>
                        <MenuItem value={"16 - Charente"}>16 - Charente</MenuItem>
                        <MenuItem value={"17 - Charente-Maritime"}>17 - Charente-Maritime</MenuItem>
                        <MenuItem value={"18 - Cher"}>18 - Cher</MenuItem>
                        <MenuItem value={"19 - Corrèze"}>19 - Corrèze</MenuItem>
                        <MenuItem value={"2A - Corse-du-Sud"}>2A - Corse-du-Sud</MenuItem>
                        <MenuItem value={"2B - Haute-Corse"}>2B - Haute-Corse</MenuItem>
                        <MenuItem value={"21 - Côte-d'Or"}>21 - Côte-d'Or</MenuItem>
                        <MenuItem value={"22 - Côtes d'Armor"}>22 - Côtes d'Armor</MenuItem>
                        <MenuItem value={"23 - Creuse"}>23 - Creuse</MenuItem>
                        <MenuItem value={"24 - Dordogne"}>24 - Dordogne</MenuItem>
                        <MenuItem value={"25 - Doubs"}>25 - Doubs</MenuItem>
                        <MenuItem value={"26 - Drôme"}>26 - Drôme</MenuItem>
                        <MenuItem value={"27 - Eure"}>27 - Eure</MenuItem>
                        <MenuItem value={"28 - Eure-et-Loir"}>28 - Eure-et-Loir</MenuItem>
                        <MenuItem value={"29 - Finistère"}>29 - Finistère</MenuItem>
                        <MenuItem value={"30 - Gard"}>30 - Gard</MenuItem>
                        <MenuItem value={"31 - Haute-Garonne"}>31 - Haute-Garonne</MenuItem>
                        <MenuItem value={"32 - Gers"}>32 - Gers</MenuItem>
                        <MenuItem value={"33 - Gironde"}>33 - Gironde</MenuItem>
                        <MenuItem value={"34 - Hérault"}>34 - Hérault</MenuItem>
                        <MenuItem value={"35 - Ille-et-Vilaine"}>35 - Ille-et-Vilaine</MenuItem>
                        <MenuItem value={"36 - Indre"}>36 - Indre</MenuItem>
                        <MenuItem value={"37 - Indre-et-Loire"}>37 - Indre-et-Loire</MenuItem>
                        <MenuItem value={"38 - Isère"}>38 - Isère</MenuItem>
                        <MenuItem value={"39 - Jura"}>39 - Jura</MenuItem>
                        <MenuItem value={"40 - Landes"}>40 - Landes</MenuItem>
                        <MenuItem value={"41 - Loir-et-Cher"}>41 - Loir-et-Cher</MenuItem>
                        <MenuItem value={"42 - Loire"}>42 - Loire</MenuItem>
                        <MenuItem value={"43 - Haute-Loire"}>43 - Haute-Loire</MenuItem>
                        <MenuItem value={"44 - Loire-Atlantique"}>44 - Loire-Atlantique</MenuItem>
                        <MenuItem value={"45 - Loiret"}>45 - Loiret</MenuItem>
                        <MenuItem value={"46 - Lot"}>46 - Lot</MenuItem>
                        <MenuItem value={"47 - Lot-et-Garonne"}>47 - Lot-et-Garonne</MenuItem>
                        <MenuItem value={"48 - Lozère"}>48 - Lozère</MenuItem>
                        <MenuItem value={"49 - Maine-et-Loire"}>49 - Maine-et-Loire</MenuItem>
                        <MenuItem value={"50 - Manche"}>50 - Manche</MenuItem>
                        <MenuItem value={"51 - Marne"}>51 - Marne</MenuItem>
                        <MenuItem value={"52 - Haute-Marne"}>52 - Haute-Marne</MenuItem>
                        <MenuItem value={"53 - Mayenne"}>53 - Mayenne</MenuItem>
                        <MenuItem value={"54 - Meurthe-et-Moselle"}>54 - Meurthe-et-Moselle</MenuItem>
                        <MenuItem value={"55 - Meuse"}>55 - Meuse</MenuItem>
                        <MenuItem value={"56 - Morbihan"}>56 - Morbihan</MenuItem>
                        <MenuItem value={"57 - Moselle"}>57 - Moselle</MenuItem>
                        <MenuItem value={"58 - Nièvre"}>58 - Nièvre</MenuItem>
                        <MenuItem value={"59 - Nord"}>59 - Nord</MenuItem>
                        <MenuItem value={"60 - Oise"}>60 - Oise</MenuItem>
                        <MenuItem value={"61 - Orne"}>61 - Orne</MenuItem>
                        <MenuItem value={"62 - Pas-de-Calais"}>62 - Pas-de-Calais</MenuItem>
                        <MenuItem value={"63 - Puy-de-Dôme"}>63 - Puy-de-Dôme</MenuItem>
                        <MenuItem value={"64 - Pyrénées-Atlantiques"}>64 - Pyrénées-Atlantiques</MenuItem>
                        <MenuItem value={"65 - Hautes-Pyrénées"}>65 - Hautes-Pyrénées</MenuItem>
                        <MenuItem value={"66 - Pyrénées-Orientales"}>66 - Pyrénées-Orientales</MenuItem>
                        <MenuItem value={"67 - Bas-Rhin"}>67 - Bas-Rhin</MenuItem>
                        <MenuItem value={"68 - Haut-Rhin"}>68 - Haut-Rhin</MenuItem>
                        <MenuItem value={"69 - Rhône"}>69 - Rhône</MenuItem>
                        <MenuItem value={"70 - Haute-Saône"}>70 - Haute-Saône</MenuItem>
                        <MenuItem value={"71 - Saône-et-Loire"}>71 - Saône-et-Loire</MenuItem>
                        <MenuItem value={"72 - Sarthe"}>72 - Sarthe</MenuItem>
                        <MenuItem value={"73 - Savoie"}>73 - Savoie</MenuItem>
                        <MenuItem value={"74 - Haute-Savoie"}>74 - Haute-Savoie</MenuItem>
                        <MenuItem value={"75 - Paris"}>75 - Paris</MenuItem>
                        <MenuItem value={"76 - Seine-Maritime"}>76 - Seine-Maritime</MenuItem>
                        <MenuItem value={"77 - Seine-et-Marne"}>77 - Seine-et-Marne</MenuItem>
                        <MenuItem value={"78 - Yvelines"}>78 - Yvelines</MenuItem>
                        <MenuItem value={"79 - Deux-Sèvres"}>79 - Deux-Sèvres</MenuItem>
                        <MenuItem value={"80 - Somme"}>80 - Somme</MenuItem>
                        <MenuItem value={"81 - Tarn"}>81 - Tarn</MenuItem>
                        <MenuItem value={"82 - Tarn-et-Garonne"}>82 - Tarn-et-Garonne</MenuItem>
                        <MenuItem value={"83 - Var"}>83 - Var</MenuItem>
                        <MenuItem value={"84 - Vaucluse"}>84 - Vaucluse</MenuItem>
                        <MenuItem value={"85 - Vendée"}>85 - Vendée</MenuItem>
                        <MenuItem value={"86 - Vienne"}>86 - Vienne</MenuItem>
                        <MenuItem value={"87 - Haute-Vienne"}>87 - Haute-Vienne</MenuItem>
                        <MenuItem value={"88 - Vosges"}>88 - Vosges</MenuItem>
                        <MenuItem value={"89 - Yonne"}>89 - Yonne</MenuItem>
                        <MenuItem value={"90 - Territoire-de-Belfort"}>90 - Territoire-de-Belfort</MenuItem>
                        <MenuItem value={"91 - Essonne"}>91 - Essonne</MenuItem>
                        <MenuItem value={"92 - Hauts-de-Seine"}>92 - Hauts-de-Seine</MenuItem>
                        <MenuItem value={"93 - Seine-Saint-Denis"}>93 - Seine-Saint-Denis</MenuItem>
                        <MenuItem value={"94 - Val-de-Marne"}>94 - Val-de-Marne</MenuItem>
                        <MenuItem value={"95 - Val-D'Oise"}>95 - Val-D'Oise</MenuItem>
                        <MenuItem value={"971 - Guadeloupe"}>971 - Guadeloupe</MenuItem>
                        <MenuItem value={"972 - Martinique"}>972 - Martinique</MenuItem>
                        <MenuItem value={"973 - Guyane"}>973 - Guyane</MenuItem>
                        <MenuItem value={"974 - La Réunion"}>974 - La Réunion</MenuItem>
                        <MenuItem value={"976 - Mayotte"}>976 - Mayotte</MenuItem>
                        <MenuItem value={"975 - Saint-Pierre-et-Miquelon"}>975 - Saint-Pierre-et-Miquelon</MenuItem>
                        <MenuItem value={"977 - Saint-Barthélémy"}>977 - Saint-Barthélémy</MenuItem>
                        <MenuItem value={"978 - Saint-Martin"}>978 - Saint-Martin</MenuItem>
                        <MenuItem value={"987 - Polynésie française"}>987 - Polynésie française</MenuItem>
                        <MenuItem value={"989 - Île de Clipperton"}>989 - Île de Clipperton</MenuItem>
                        <MenuItem value={"986 - Wallis-et-Futuna"}>986 - Wallis-et-Futuna</MenuItem>
                        <MenuItem value={"988 - Nouvelle-Calédonie"}>988 - Nouvelle-Calédonie</MenuItem>
                        <MenuItem value={"984 - Terres australes et antarctiques françaises"}>984 - Terres australes et antarctiques françaises</MenuItem>
                      </Select>
                  </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <MKInput value={telephone} success type="tel" onChange={(e)=>{setTelephone(e.target.value)}}  label={<span >Téléphone*</span>} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6} >
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Cursus*</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={cursus}
                        label="Cursus"
                        onChange={handleChange}
                        style={{height:"42px"}}
                      >
                        <MenuItem value={"Médecine"}>Médecine</MenuItem>
                        <MenuItem value={"Dentaire"}>Dentaire</MenuItem>
                        <MenuItem value={"Pharmacie"}>Pharmacie</MenuItem>
                        <MenuItem value={"Kinésithérapie"}>Kinésithérapie</MenuItem>
                        <MenuItem value={"Maïeutique"}>Maïeutique</MenuItem>
                        <MenuItem value={"Soins infirmiers"}>Soins infirmiers</MenuItem>
                        <MenuItem value={"Autre"}>Autre</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  
                  <Grid item xs={12} md={12} >
                   <FormControlLabel control={<Checkbox defaultChecked onChange={(event ) => {
                          const a = duplicate(value)
                          a.etudiant = value && value.etudiant ? false : true
                          setValue(a)
                          setRefresh(!refresh)
                        }} checked={value && value.etudiant ? true : false}/>} label="Je suis étudiant" />
                  </Grid>
                  {checkEtudiant && (
                  <Grid item xs={12} md={6} >
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Année d'étud</InputLabel>
                      <Select

                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={annee}
                        label="Année d'étude"
                        onChange={handleChangeAnnee}
                        style={{height:50}}
                      >
                        <MenuItem value={"1"}>1</MenuItem>
                        <MenuItem value={"2"}>2</MenuItem>
                        <MenuItem value={"3"}>3</MenuItem>
                        <MenuItem value={"4"}>4</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                        <MenuItem value={"6"}>6</MenuItem>
                        <MenuItem value={"Interne"}>Interne</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  )}
                  {annee == 'Interne' ?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.semestre ? value.semestre : ''}
                        options={list_semestres}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.semestre = newValue
                          setValue(a)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Semestre' />}
                      />
                    </Grid>
                  ):null
                  }
                  <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.profession ? value.profession : ''}
                        options={list_para}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.profession = newValue
                          setValue(a)
                          setRefresh(!refresh)
                         
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Profession' />}
                      />
                    </Grid>
                    {(annee == 'Interne' && value && value.profession && value.profession == 'Médecin') || (!checkEtudiant&& value && value.profession && value.profession === 'Médecin')?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.specialite ? value.specialite : ''}
                        options={list_medecin}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.specialite = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Spécialité' />}
                      />
                    </Grid>
                  ):null
                  }
                  {(annee == 'Interne' && value && value.profession && value.profession == 'Chirurgien dentiste') || (!checkEtudiant&& value && value.profession && value.profession === 'Chirurgien dentiste')?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.specialite ? value.specialite : ''}
                        options={list_chir_dent}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.specialite = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Spécialité' />}
                      />
                    </Grid>
                  ):null
                  }
                  {/* {(annee == 'Interne' && value && value.profession && value.profession == 'Chirurgien dentiste') || (!checkEtudiant&& value && value.profession && value.profession === 'Chirurgien dentiste')?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.specialite ? value.specialite : ''}
                        options={list_chir_dent}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.specialite = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Spécialité' />}
                      />
                    </Grid>
                  ):null
                  } */}
                {(annee == 'Interne' && value && value.profession && value.profession == 'Infirmier diplômé d’État (IDE)') || (!checkEtudiant&& value && value.profession && value.profession === 'Infirmier diplômé d’État (IDE)')?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.specialite ? value.specialite : ''}
                        options={list_infirmier}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.specialite = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Spécialité' />}
                      />
                    </Grid>
                  ):null
                  }
                  {(checkEtudiant )?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.post_rech ? value.post_rech : ''}
                        options={list_post_rech}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.post_rech = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Poste recherché' />}
                      />
                    </Grid>
                  ):null
                  }
                  {(!checkEtudiant )?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.post_rech ? value.post_rech : ''}
                        options={list_post_rech_pro}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.post_rech = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Poste recherché' />}
                      />
                    </Grid>
                  ):null
                  }
                  {(true )?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.type_contrat ? value.type_contrat : ''}
                        options={list_type_contrat}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.type_contrat = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Type de contrat' />}
                      />
                    </Grid>
                  ):null
                  }
                  {/* OUI NON */}
                  <Grid item xs={12} md={6} >
                      <FormControl fullWidth style={{height:55}}>
                        <InputLabel id='controlled-select-label'>Je suis flexible sur le type de contrat recherché</InputLabel>
                        <Select
                          value={value && value.flexible_contrat ? value.flexible_contrat : ''}
                          label='Je suis flexible sur le type de contrat recherché'
                          id='controlled-select'
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.flexible_contrat = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          style={{height:55}}
                          labelId='controlled-select-label'
                        >
                          <MenuItem value={"Oui"}>Oui</MenuItem>
                          <MenuItem value={"Non"}>Non</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                  <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.type_etab ? value.type_etab : ''}
                        options={list_type_etab}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.type_etab = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label="Type d'établissement souhaité" />}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} >
                      <FormControl fullWidth>
                        <InputLabel id='controlled-select-label'>Expériences</InputLabel>
                        <Select
                          value={value && value.experiences ? value.experiences : ''}
                          label='Expériences'
                          id='controlled-select'
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.experiences = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          style={{height:50}}
                          labelId='controlled-select-label'
                        >
                          <MenuItem value={"0 à 1 an"}>0 à 1 an</MenuItem>
                          <MenuItem value={"1 à 5 ans"}>1 à 5 ans</MenuItem>
                          <MenuItem value={"5 à 10 ans"}>5 à 10 ans</MenuItem>
                          <MenuItem value={"Plus de 10 ans"}>Plus de 10 ans</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Vous pouvez ajouter ici les éventuelles dates ou périodes recherchées"
                        value={value && value.description ? value.description : ''}
                        label='Description'
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.description = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='textarea-outlined-controlled'
                      />
                     
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                      <MuiFileInput value={file} onChange={handleChangeFile} label="Chargez votre CV"/>
                  </Grid>

                    <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        multiline
                        maxRows={4}
                        value={value && value.mission_spe ? value.mission_spe : ''}
                        label='Missions recherchées'
                        
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.mission_spe = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='textarea-outlined-controlled'
                      />
                     
                    </Grid> */}

                 
                </Grid>
                {error !== "" ? (<span className="redpass" style={{textAlign:"center", justifyContent:"center",display:"flex",marginTop:"15px"}}>{error}</span>) : null}
                <Grid container item justifyContent="center" xs={12} my={4}>
                  <MKButton onClick={()=>{onSubmit()}} variant="contained" color="info">
                    Envoyer
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
          ) : (
            <Grid container item xs={12} lg={8} sx={{ mx: "auto" }} py={3}>
            <MKBox width="100%" component="form" method="post" autocomplete="off">
              <MKBox p={3}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        value={value && value.prenom ? value.prenom : ''}
                        label='Prénom'
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.prenom = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='textarea-outlined-controlled'
                      />
                     
                    </Grid>
                    <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        value={value && value.nom ? value.nom : ''}
                        label='Nom'
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.nom = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='textarea-outlined-controlled'
                      />
                     
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <TextField
                          fullWidth
                          value={value && value.email ? value.email : ''}
                          label='Email'
                          
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.email = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          id='textarea-outlined-controlled'
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <TextField
                          fullWidth
                          value={value && value.tel ? value.tel : ''}
                          label='Téléphone'
                          
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.tel = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          id='textarea-outlined-controlled'
                        />
                    </Grid>
                  <Grid item xs={12} md={6} >
                  <FormControl fullWidth >
                        <InputLabel id='controlled-select-label'>Département</InputLabel>
                        <Select
                          value={value && value.departement ? value.departement : ''}
                          label='Département'
                          id='controlled-select'
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.departement = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          style={{height:45}}
                          labelId='controlled-select-label'
                        >
                        <MenuItem value={"01 - Ain"}>01 - Ain</MenuItem>
                        <MenuItem value={"02 - Aisne"}>02 - Aisne</MenuItem>
                        <MenuItem value={"03 - Allier"}>03 - Allier</MenuItem>
                        <MenuItem value={"04 - Alpes-de-Haute-Provence"}>04 - Alpes-de-Haute-Provence</MenuItem>
                        <MenuItem value={"05 - Hautes-Alpes"}>05 - Hautes-Alpes</MenuItem>
                        <MenuItem value={"06 - Alpes-Maritimes"}>06 - Alpes-Maritimes</MenuItem>
                        <MenuItem value={"07 - Ardèche"}>07 - Ardèche</MenuItem>
                        <MenuItem value={"08 - Ardennes"}>08 - Ardennes</MenuItem>
                        <MenuItem value={"09 - Ariège"}>09 - Ariège</MenuItem>
                        <MenuItem value={"10 - Aube"}>10 - Aube</MenuItem>
                        <MenuItem value={"11 - Aude"}>11 - Aude</MenuItem>
                        <MenuItem value={"12 - Aveyron"}>12 - Aveyron</MenuItem>
                        <MenuItem value={"13 - Bouches-du-Rhône"}>13 - Bouches-du-Rhône</MenuItem>
                        <MenuItem value={"14 - Calvados"}>14 - Calvados</MenuItem>
                        <MenuItem value={"15 - Cantal"}>15 - Cantal</MenuItem>
                        <MenuItem value={"16 - Charente"}>16 - Charente</MenuItem>
                        <MenuItem value={"17 - Charente-Maritime"}>17 - Charente-Maritime</MenuItem>
                        <MenuItem value={"18 - Cher"}>18 - Cher</MenuItem>
                        <MenuItem value={"19 - Corrèze"}>19 - Corrèze</MenuItem>
                        <MenuItem value={"2A - Corse-du-Sud"}>2A - Corse-du-Sud</MenuItem>
                        <MenuItem value={"2B - Haute-Corse"}>2B - Haute-Corse</MenuItem>
                        <MenuItem value={"21 - Côte-d'Or"}>21 - Côte-d'Or</MenuItem>
                        <MenuItem value={"22 - Côtes d'Armor"}>22 - Côtes d'Armor</MenuItem>
                        <MenuItem value={"23 - Creuse"}>23 - Creuse</MenuItem>
                        <MenuItem value={"24 - Dordogne"}>24 - Dordogne</MenuItem>
                        <MenuItem value={"25 - Doubs"}>25 - Doubs</MenuItem>
                        <MenuItem value={"26 - Drôme"}>26 - Drôme</MenuItem>
                        <MenuItem value={"27 - Eure"}>27 - Eure</MenuItem>
                        <MenuItem value={"28 - Eure-et-Loir"}>28 - Eure-et-Loir</MenuItem>
                        <MenuItem value={"29 - Finistère"}>29 - Finistère</MenuItem>
                        <MenuItem value={"30 - Gard"}>30 - Gard</MenuItem>
                        <MenuItem value={"31 - Haute-Garonne"}>31 - Haute-Garonne</MenuItem>
                        <MenuItem value={"32 - Gers"}>32 - Gers</MenuItem>
                        <MenuItem value={"33 - Gironde"}>33 - Gironde</MenuItem>
                        <MenuItem value={"34 - Hérault"}>34 - Hérault</MenuItem>
                        <MenuItem value={"35 - Ille-et-Vilaine"}>35 - Ille-et-Vilaine</MenuItem>
                        <MenuItem value={"36 - Indre"}>36 - Indre</MenuItem>
                        <MenuItem value={"37 - Indre-et-Loire"}>37 - Indre-et-Loire</MenuItem>
                        <MenuItem value={"38 - Isère"}>38 - Isère</MenuItem>
                        <MenuItem value={"39 - Jura"}>39 - Jura</MenuItem>
                        <MenuItem value={"40 - Landes"}>40 - Landes</MenuItem>
                        <MenuItem value={"41 - Loir-et-Cher"}>41 - Loir-et-Cher</MenuItem>
                        <MenuItem value={"42 - Loire"}>42 - Loire</MenuItem>
                        <MenuItem value={"43 - Haute-Loire"}>43 - Haute-Loire</MenuItem>
                        <MenuItem value={"44 - Loire-Atlantique"}>44 - Loire-Atlantique</MenuItem>
                        <MenuItem value={"45 - Loiret"}>45 - Loiret</MenuItem>
                        <MenuItem value={"46 - Lot"}>46 - Lot</MenuItem>
                        <MenuItem value={"47 - Lot-et-Garonne"}>47 - Lot-et-Garonne</MenuItem>
                        <MenuItem value={"48 - Lozère"}>48 - Lozère</MenuItem>
                        <MenuItem value={"49 - Maine-et-Loire"}>49 - Maine-et-Loire</MenuItem>
                        <MenuItem value={"50 - Manche"}>50 - Manche</MenuItem>
                        <MenuItem value={"51 - Marne"}>51 - Marne</MenuItem>
                        <MenuItem value={"52 - Haute-Marne"}>52 - Haute-Marne</MenuItem>
                        <MenuItem value={"53 - Mayenne"}>53 - Mayenne</MenuItem>
                        <MenuItem value={"54 - Meurthe-et-Moselle"}>54 - Meurthe-et-Moselle</MenuItem>
                        <MenuItem value={"55 - Meuse"}>55 - Meuse</MenuItem>
                        <MenuItem value={"56 - Morbihan"}>56 - Morbihan</MenuItem>
                        <MenuItem value={"57 - Moselle"}>57 - Moselle</MenuItem>
                        <MenuItem value={"58 - Nièvre"}>58 - Nièvre</MenuItem>
                        <MenuItem value={"59 - Nord"}>59 - Nord</MenuItem>
                        <MenuItem value={"60 - Oise"}>60 - Oise</MenuItem>
                        <MenuItem value={"61 - Orne"}>61 - Orne</MenuItem>
                        <MenuItem value={"62 - Pas-de-Calais"}>62 - Pas-de-Calais</MenuItem>
                        <MenuItem value={"63 - Puy-de-Dôme"}>63 - Puy-de-Dôme</MenuItem>
                        <MenuItem value={"64 - Pyrénées-Atlantiques"}>64 - Pyrénées-Atlantiques</MenuItem>
                        <MenuItem value={"65 - Hautes-Pyrénées"}>65 - Hautes-Pyrénées</MenuItem>
                        <MenuItem value={"66 - Pyrénées-Orientales"}>66 - Pyrénées-Orientales</MenuItem>
                        <MenuItem value={"67 - Bas-Rhin"}>67 - Bas-Rhin</MenuItem>
                        <MenuItem value={"68 - Haut-Rhin"}>68 - Haut-Rhin</MenuItem>
                        <MenuItem value={"69 - Rhône"}>69 - Rhône</MenuItem>
                        <MenuItem value={"70 - Haute-Saône"}>70 - Haute-Saône</MenuItem>
                        <MenuItem value={"71 - Saône-et-Loire"}>71 - Saône-et-Loire</MenuItem>
                        <MenuItem value={"72 - Sarthe"}>72 - Sarthe</MenuItem>
                        <MenuItem value={"73 - Savoie"}>73 - Savoie</MenuItem>
                        <MenuItem value={"74 - Haute-Savoie"}>74 - Haute-Savoie</MenuItem>
                        <MenuItem value={"75 - Paris"}>75 - Paris</MenuItem>
                        <MenuItem value={"76 - Seine-Maritime"}>76 - Seine-Maritime</MenuItem>
                        <MenuItem value={"77 - Seine-et-Marne"}>77 - Seine-et-Marne</MenuItem>
                        <MenuItem value={"78 - Yvelines"}>78 - Yvelines</MenuItem>
                        <MenuItem value={"79 - Deux-Sèvres"}>79 - Deux-Sèvres</MenuItem>
                        <MenuItem value={"80 - Somme"}>80 - Somme</MenuItem>
                        <MenuItem value={"81 - Tarn"}>81 - Tarn</MenuItem>
                        <MenuItem value={"82 - Tarn-et-Garonne"}>82 - Tarn-et-Garonne</MenuItem>
                        <MenuItem value={"83 - Var"}>83 - Var</MenuItem>
                        <MenuItem value={"84 - Vaucluse"}>84 - Vaucluse</MenuItem>
                        <MenuItem value={"85 - Vendée"}>85 - Vendée</MenuItem>
                        <MenuItem value={"86 - Vienne"}>86 - Vienne</MenuItem>
                        <MenuItem value={"87 - Haute-Vienne"}>87 - Haute-Vienne</MenuItem>
                        <MenuItem value={"88 - Vosges"}>88 - Vosges</MenuItem>
                        <MenuItem value={"89 - Yonne"}>89 - Yonne</MenuItem>
                        <MenuItem value={"90 - Territoire-de-Belfort"}>90 - Territoire-de-Belfort</MenuItem>
                        <MenuItem value={"91 - Essonne"}>91 - Essonne</MenuItem>
                        <MenuItem value={"92 - Hauts-de-Seine"}>92 - Hauts-de-Seine</MenuItem>
                        <MenuItem value={"93 - Seine-Saint-Denis"}>93 - Seine-Saint-Denis</MenuItem>
                        <MenuItem value={"94 - Val-de-Marne"}>94 - Val-de-Marne</MenuItem>
                        <MenuItem value={"95 - Val-D'Oise"}>95 - Val-D'Oise</MenuItem>
                        <MenuItem value={"971 - Guadeloupe"}>971 - Guadeloupe</MenuItem>
                        <MenuItem value={"972 - Martinique"}>972 - Martinique</MenuItem>
                        <MenuItem value={"973 - Guyane"}>973 - Guyane</MenuItem>
                        <MenuItem value={"974 - La Réunion"}>974 - La Réunion</MenuItem>
                        <MenuItem value={"976 - Mayotte"}>976 - Mayotte</MenuItem>
                        <MenuItem value={"975 - Saint-Pierre-et-Miquelon"}>975 - Saint-Pierre-et-Miquelon</MenuItem>
                        <MenuItem value={"977 - Saint-Barthélémy"}>977 - Saint-Barthélémy</MenuItem>
                        <MenuItem value={"978 - Saint-Martin"}>978 - Saint-Martin</MenuItem>
                        <MenuItem value={"987 - Polynésie française"}>987 - Polynésie française</MenuItem>
                        <MenuItem value={"989 - Île de Clipperton"}>989 - Île de Clipperton</MenuItem>
                        <MenuItem value={"986 - Wallis-et-Futuna"}>986 - Wallis-et-Futuna</MenuItem>
                        <MenuItem value={"988 - Nouvelle-Calédonie"}>988 - Nouvelle-Calédonie</MenuItem>
                        <MenuItem value={"984 - Terres australes et antarctiques françaises"}>984 - Terres australes et antarctiques françaises</MenuItem>
                      </Select>
                  </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={12} >
                    <Typography variant="body2" style={{color:'#283351'}}>Type de candidat recherché</Typography>
                    <FormControlLabel control={<Checkbox defaultChecked onChange={(event ) => {
                      const a = duplicate(value)
                      a.professionnel_sante_ann = value && value.professionnel_sante_ann ? false : true
                      setValue(a)
                      setRefresh(!refresh)
                    }} checked={value && value.professionnel_sante_ann ? true : false}/>} label="Professionnel de santé" />
                    <FormControlLabel control={<Checkbox defaultChecked onChange={(event ) => {
                            const a = duplicate(value)
                            a.etudiant_ann = value && value.etudiant_ann ? false : true
                            setValue(a)
                            setRefresh(!refresh)
                          }} checked={value && value.etudiant_ann ? true : false}/>} label="Etudiant" />
               
                  </Grid>

                  {value && value.professionnel_sante_ann ?
                  <Grid item xs={12} md={6} >
                    <Autocomplete
                      value={value && value.profession_ann ? value.profession_ann : ''}
                      options={list_para}
                      onChange={(event, newValue ) => {
                        const a = duplicate(value)
                        a.profession_ann = newValue
                        setValue(a)
                        setRefresh(!refresh)
                      
                      }}
                      id='autocomplete-controlled'
                      //getOptionLabel={option => option.title || ''}
                      renderInput={params => <TextField {...params} label='Profession recherchée' />}
                    />
                  </Grid>
                  :null}

                  {(value && value.profession_ann && value.profession_ann == 'Médecin') ?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.specialite_ann ? value.specialite_ann : ''}
                        options={list_medecin}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.specialite_ann = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Spécialité' />}
                      />
                    </Grid>
                  ):null
                  }

                  {(value && value.profession_ann && value.profession_ann == 'Chirurgien dentiste') ?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.specialite_ann ? value.specialite_ann : ''}
                        options={list_chir_dent}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.specialite_ann = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Spécialité' />}
                      />
                    </Grid>
                  ):null
                  }

                  {( value && value.profession_ann && value.profession_ann == 'Infirmier diplômé d’État (IDE)') ?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.specialite_ann ? value.specialite_ann : ''}
                        options={list_infirmier}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.specialite_ann = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Spécialité' />}
                      />
                    </Grid>
                  ):null
                  }

                  {(true )?
                  (
                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.type_contrat_ann ? value.type_contrat_ann : ''}
                        options={list_type_contrat}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.type_contrat_ann = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label='Type de contrat recherché' />}
                      />
                    </Grid>
                  ):null
                  }
                  <Grid item xs={12} md={6} >
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Type de rémunération</InputLabel>
                      <Select
                        value={value && value.type_remu_ann ? value.type_remu_ann : ''}
                        label='Type de rémunération'
                        id='controlled-select'
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.type_remu_ann = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        style={{height:50}}
                      >
                        <MenuItem value={"rétrocession en %"}>rétrocession en %</MenuItem>
                        <MenuItem value={"salaire mensuel brut en EUR"}>salaire mensuel brut en EUR</MenuItem>
                        <MenuItem value={"salaire horaire brut en EUR"}>salaire horaire brut</MenuItem>
                        <MenuItem value={"non précisé"}>non précisé</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {(value && value.type_remu_ann) && (value.type_remu_ann == "rétrocession en %" ||value.type_remu_ann == "salaire mensuel brut en EUR") ?
                  <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        value={value && value.montant_ann ? value.montant_ann : ''}
                        label='Montant'
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.montant_ann = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='textarea-outlined-controlled'
                      />
                     
                    </Grid>
                  : null}

                  
                     <Grid item xs={12} md={12} >
                     <FormControlLabel control={<Checkbox defaultChecked onChange={(event ) => {
                       const a = duplicate(value)
                       a.affiche_remu_ann = value && value.affiche_remu_ann ? false : true
                       setValue(a)
                       setRefresh(!refresh)
                     }} checked={value && value.affiche_remu_ann ? true : false}/>} label="Ne pas afficher la rémunération dans l'annonce" />
                    </Grid>
                  
                    <Grid item xs={12} sm={6} style={{ marginTop: 17 }}>
                    <DatePickerWrapper>
                      <DatePicker
                        selected={value && value.date_ann ? value.date_ann : null}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd/MM/yyyy"
                        placeholderText=''
                        customInput={<CustomInput />}
                        id='form-layouts-separator-date'
                        onChange={(date) => {
                          const a = duplicate(value)
                          a.date_ann = date
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                      />
                      </DatePickerWrapper>
                    </Grid>

                    <Grid item xs={12} sm={6} style={{ marginTop: 17 }}>
                    <DatePickerWrapper>
                      <DatePicker
                        selected={value && value.date_fin_ann ? value.date_fin_ann : null}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd/MM/yyyy"

                        placeholderText=''
                        customInput={<CustomInputFin />}
                        id='form-layouts-separator-date'
                        onChange={(date) => {
                          const a = duplicate(value)
                          a.date_fin_ann = date
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                      />
                      </DatePickerWrapper>
                    </Grid>

                    <Grid item xs={12} md={6} >
                      <FormControl fullWidth style={{height:55}}>
                        <InputLabel id='controlled-select-label'>Flexibilité sur le type de contrat recherché</InputLabel>
                        <Select
                          value={value && value.flexible_contrat_ann ? value.flexible_contrat_ann : ''}
                          label='Flexibilité sur le type de contrat recherché'
                          id='controlled-select'
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.flexible_contrat_ann = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          style={{height:55}}
                          labelId='controlled-select-label'
                        >
                          <MenuItem value={"OUI"}>OUI</MenuItem>
                          <MenuItem value={"NON"}>NON</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} >
                      <Autocomplete
                        value={value && value.type_etab_ann ? value.type_etab_ann : ''}
                        options={list_type_etab}
                        onChange={(event, newValue ) => {
                          const a = duplicate(value)
                          a.type_etab_ann = newValue
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='autocomplete-controlled'
                        //getOptionLabel={option => option.title || ''}
                        renderInput={params => <TextField {...params} label="Votre établissement" />}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} >
                      <FormControl fullWidth>
                        <InputLabel id='controlled-select-label'>Expérience requise</InputLabel>
                        <Select
                          value={value && value.experiences_ann ? value.experiences_ann : ''}
                          label='Expériences'
                          id='controlled-select'
                          onChange={(event ) => {
                            const a = duplicate(value)
                            a.experiences_ann = event.target.value
                            setValue(a)
                            setRefresh(!refresh)
                          }}
                          style={{height:50}}
                          labelId='controlled-select-label'
                        >
                          <MenuItem value={"0 à 1 an"}>0 à 1 an</MenuItem>
                          <MenuItem value={"1 à 5 ans"}>1 à 5 ans</MenuItem>
                          <MenuItem value={"5 à 10 ans"}>5 à 10 ans</MenuItem>
                          <MenuItem value={"Plus de 10 ans"}>Plus de 10 ans</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={value && value.description_ann ? value.description_ann : ''}
                        label="Description de l'annonce"
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.description_ann = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='textarea-outlined-controlled'
                      />
                     
                    </Grid>

                   {/*  <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        multiline
                        maxRows={4}
                        value={value && value.mission_spe_ann ? value.mission_spe_ann : ''}
                        label='Missions spécifiques recherchées'
                        onChange={(event ) => {
                          const a = duplicate(value)
                          a.mission_spe_ann = event.target.value
                          setValue(a)
                          setRefresh(!refresh)
                        }}
                        id='textarea-outlined-controlled'
                      />
                     
                    </Grid> */}




                  
                  {/* <Grid item xs={12} md={6} >
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Type d'établissement*</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={typeEtablissement}
                        label="Type d'établissement"
                        onChange={handleChangeTypeEtablissement}
                        style={{height:"42px"}}
                      >
                        <MenuItem value={"EHPAD"}>EHPAD</MenuItem>
                        <MenuItem value={"Hôpital et clinique"}>Hôpital et clinique</MenuItem>
                        <MenuItem value={"Cabinet de ville"}>Cabinet de ville</MenuItem>
                        <MenuItem value={"Pharmacie"}>Pharmacie</MenuItem>
                        <MenuItem value={"Autre"}>Autre</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput onChange={(e)=>{setNomEtablissement(e.target.value)}} value={nomEtablissement} type="text" success label={<span >Nom de l'établissement*</span>} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput onChange={(e)=>{setNom(e.target.value)}} value={nom} type="text" success label={<span >Nom</span>} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput onChange={(e)=>{setPrenom(e.target.value)}} value={prenom} type="text" success label={<span >Prénom</span>} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput onChange={(e)=>{setCodePostal(e.target.value)}} value={codePostal} type="text" success label={<span >Code Postal*</span>} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput value={posteEtablissement} success type="text" onChange={(e)=>{setPosteEtablissement(e.target.value)}} label={<span >Votre poste au sein de l'établissement</span>} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput onChange={(e)=>{setTelephone(e.target.value)}} value={telephone} success type="tel" label={<span >Numéro de téléphone*</span>} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput onChange={(e)=>{setEmail(e.target.value)}} value={email} success type="email" label={<span >E-mail*</span>} fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6} >
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">Vous recherchez</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={talentChercher}
                        onChange={handleChangeTalentChercher}
                        input={<OutlinedInput label="Vous recherchez" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        style={{height:"42px"}}
                      >
                        {metiers.map((metier) => (
                          <MenuItem key={metier} value={metier}>
                            <Checkbox checked={talentChercher.indexOf(metier) > -1} />
                            <ListItemText primary={metier} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} >
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">Type de contrat recherché</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={typeContratChercher}
                        onChange={handleChangeTypeContratChercher}
                        input={<OutlinedInput label="Type de contrat recherché" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        style={{height:"42px"}}
                      >
                        {type_contrat.map((contrat) => (
                          <MenuItem key={contrat} value={contrat}>
                            <Checkbox checked={typeContratChercher.indexOf(contrat) > -1} />
                            <ListItemText primary={contrat} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <MKInput
                      success
                      label="Message"
                      multiline
                      fullWidth
                      rows={6}
                      value={message}
                      onChange={(e)=>{setMessage(e.target.value)}}
                    />
                  </Grid> */}
                  
                  {/* <Grid item xs={12} md={6}>
                      <MuiFileInput value={file} onChange={handleChangeFile} label="Chargez votre CV"/>
                  </Grid> */}
                </Grid>
                {error !== "" ? (<span className="redpass" style={{textAlign:"center", justifyContent:"center",display:"flex",marginTop:"15px"}}>{error}</span>) : null}
                <Grid container item justifyContent="center" xs={12} my={4}>
                  <MKButton onClick={()=>{onSubmit()}}  variant="contained" color="info">
                    Envoyer
                  </MKButton>
                </Grid>
                
              </MKBox>
            </MKBox>
          </Grid>
          )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center"}}
          open={show}
          autoHideDuration={3000}
          onClose={toggleSnackbar}
          message={toastTemplate}
          action={toggleSnackbar}
          sx={toastStyles}
        />
        </Container>
        
      </MKBox>
    </>
  );
}

export default Form;