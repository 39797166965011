// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";
import MKButton from "components/MKButton";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
 
//import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import MKInput from "components/MKInput";
/* import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Information from "pages/presentation/sections/Information";
import DesignBlocks from "pages/presentation/sections/DesignBlocks";
import AuthPages from "pages/presentation/sections/AuthPages";
import Pages from "pages/presentation/sections/Pages";
import Testimonials from "pages/presentation/sections/Testimonials";
import Pricing from "pages/presentation/sections/Pricing";

// Presentation page components
import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers"; */

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import styles from "../presentation/mystyle.module.css";
import "../presentation/style.css";

function Aide() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "Connexion",
          color: "info",
        }}
        sticky
      />

  <MKBox
          component="section"
          display="grid"
          position="relative"
          minHeight="90vh"
          mr={{ xs: 0, lg: 0 }}
          mb={{ xs: 0, lg: 0 }}
          sx={{
            overflow: { xs: "hidden", lg: "visible" },
            placeItems: "center",
            backgroundColor: "#EBF0EF",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
          }}
          /* ref={headerRefSec1} */
          width="100%"
        >

<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script><iframe class="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/app1sARYMk7I8bJ4X/shrsGCi5mFCUfC8nG?backgroundColor=teal" frameborder="0" onmousewheel="" width="100%" height="800px" style={{backgroundColor: "transparent", border:" 1px solid #ccc",marginTop:"100px"}}></iframe>
  </MKBox>



       

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Aide;
