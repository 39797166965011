/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKInput from "components/MKInput";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";
import { useMediaQuery } from 'react-responsive'
import Stack from "@mui/material/Stack";
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

// Otis Kit PRO examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// HelpCenter page sections
import SocialAnalytics from "pages/Support/HelpCenter/sections/SocialAnalytics";
import Faq from "pages/Support/HelpCenter/sections/Faq";
import Features from "pages/Support/HelpCenter/sections/Features";
import Contact from "pages/Support/HelpCenter/sections/Contact";
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Form from "components/FormContact";
// Images
import bgImage from "assets/images/bg3.jpg";

import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";


// Otis Kit PRO examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Images
import bgImage1 from "assets/images/examples/color1.jpg";
import bgImage2 from "assets/images/examples/color3.jpg";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function HelpCenter() {
  const [collapse, setCollapse] = useState(false);
  const headerRef = useRef(null);
  const refContact = useRef(null);
  const handleClick = (typeForm) => {
    refContact.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 824px)'
  })
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.sorya.eu",
          label: "Connexion",
          color: "info",
        }}
        sticky
      />
      <div style={{ backgroundColor: "#DDE8E5" }}>
        <MKBox
          minHeight={isDesktopOrLaptop ?"55vh":"70vh"}
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.4),
                rgba(gradients.dark.state, 0.4)
              )}, url("https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695825767/Untitled_19_oq0rl3.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="whiteTitle">Etudiant</span>
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                mb={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="whiteTitle">La plateforme qui vous connecte en un clin d’oeil aux professionnels de santé autour de vous</span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "#EBF9F0",
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
          bgColor="#0F2022"
        >
          
         
          {/* Trois Axes */}
          {/* <MKBox component="section" py={6} mt={6}>
            <Container>
              <Grid container spacing={3} item xs={12} lg={8} mx="auto">
                <div style={{flexDirection:"column"}}>
                <MKTypography component="h5" opacity={0.9}>
                  <span className="fontThin">sorya.</span>
                  
                </MKTypography>
                <MKTypography variant="h3" mb={3}>
                  <span className="fontKionaGold">La plateforme qui vous connecte en un clic aux professionnels de santé autour de vous.</span>
                </MKTypography>
                </div>
                <MKTypography variant="body1" textAlign="justify">
                  <span className="fontParaPetit">
                    Nous sommes une équipe de professionnels compétents et passionnés. Ce qui nous
                    distingue, c&apos;est notre vision pour l&apos;avenir. Nous sommes animés par la
                    conviction que nous pouvons créer des solutions innovantes qui permette de
                    mettre en place des cercles vertueux bénéfiques à tous les acteurs impliqués.
                    <br /> <br />
                    Notre vision est de contribuer à l&apos;émergence d&apos;un monde où les
                    entreprises, les consommateurs et la société dans son ensemble peuvent prospérer
                    ensemble. Nous pensons qu&apos;il est possible de créer des solutions qui
                    offrent des avantages pour toutes les parties prenantes. En effet, nous sommes
                    convaincus que les entreprises peuvent réaliser des profits tout en ayant un
                    impact positif sur la société.
                    <br /> <br />
                    Notre équipe partage cette vision commune et travaille pour trouver des
                    solutions innovantes pour nos clients. Nous sommes enthousiastes à l&apos;idée
                    de partager notre vision et de collaborer avec vous.
                  </span>
                </MKTypography>
              </Grid>
            </Container>
          </MKBox> */}
          <MKBox component="section" py={{ xs: 3, md: 12 }}>
            <Container>
              <Grid container alignItems="center">
                <Grid item xs={12} lg={5}>
                  <MKTypography variant="h3" my={1}>
                    <span className="fontKionaGold">L’innovation en matière de recrutement au service des étudiants et professionnels de santé</span>
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2} textAlign="justify">
                    <span className="fontPara">
                    En créant un lien direct avec les structures de santé, nous révolutionnons votre recherche de missions en mettant à votre disposition des opportunités flexibles et adaptées près de chez vous !
                    </span>
                  </MKTypography>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                  <Stack>
                    <AboutUsOption
                      icon="star"
                      content={
                        <>
                          Une  application mobile pour entrer en lien direct avec des
                          <br />
                           établissements, cabinets médicaux et pharmacies
                        </>
                      }
                    />
                    <AboutUsOption
                      icon="settings"
                      content={
                        <>
                          Renseignez vos disponibilités, nous travaillons pour vous  <br />
                          proposer les meilleures offres et une gestion facilitée de vos missions
                         
                        </>
                      }
                    />
                    <AboutUsOption
                      icon="mediation"
                      content={
                        <>
                          Sélectionnez des opportunités de missions ajustables en fonction
                          <br />
                           de votre localisation, allant de quelques heures à un temps plein
                        </>
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </MKBox>


          <MKBox component="section" py={6}>
            <Container>
              <Grid
                container
                item
                xs={12}
                lg={8}
                flexDirection="column"
                textAlign="center"
                mx="auto"
                mb={3}
              >
                <MKTypography variant="h2" fontWeight="bold">
                  <span className="fontKionaGold">Les étapes clés</span>
                </MKTypography>
                {/* <MKTypography variant="body1">
                  <span className="fontThin">Nos services se décomposent en trois axes</span>
                </MKTypography> */}
              </Grid>
              <Grid container spacing={3} mt={4}>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678919242/Capture_d_e%CC%81cran_2023-03-15_a%CC%80_23.27.15_cbdyjc.png"
                    label=""
                    title={<span className="fontKBoldGold">Aide-soignant(e)</span>}
                    description={
                      <span className="fontParaPetitWhite" style={{textAlign:"right"}}>
                        L’aide soignant(e) incarne une profession dévouée au service de la santé et du bien être des patients. Il apporte un réconfort inestimable pour les patients et contribue à leur rétablissement. En France ou les besoins en personnel médical qualifié sont en constante augmentation, de nombreuses opportunités d’emploi sont disponibles pour ceux qui souhaitent embrasser cette carrière gratifiante sur la plateforme Sorya ! 
                      </span>
                    }
                    action={{
                      type: "internal",
                      route: "/inscription",
                      label: "Postuler",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678981294/original_frkkea.avif"
                    label=""
                    title={<span className="fontKBoldGold">IDE - Infirmier(e) diplômé(e) d’État</span>}
                    description={
                      <span className="fontParaPetitWhite">
                        Le métier d'infirmier(e) consiste à prodiguer des soins directs aux patients, notamment l'administration de médicaments et la surveillance des signes vitaux. Les IDE jouent un rôle essentiel dans les établissements de santé en assurant le bien-être des patients. En France où les besoins en soins de santé sont en constante évolution, le métier d'IDE offre des opportunités stimulantes !
                      </span>
                    }
                    action={{
                      type: "internal",
                      route: "/inscription",
                      label: "Postuler",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678981394/accompagnement-du-createur-min_h5ntym.jpg"
                    label=""
                    title={<span className="fontKBoldGold">Auxiliaire de puériculture</span>}
                    description={
                      <span className="fontParaPetitWhite">
                        Le métier d'auxiliaire de puériculture consiste à prendre en charge les soins et le bien-être des nourrissons, des enfants en bas âge et des adolescents au sein d'établissements de santé, de crèches ou de services de pédiatrie. Les auxiliaires de puériculture sont responsables de l'hygiène, de l'alimentation, et de la surveillance médicale des enfants, tout en assurant un soutien affectif essentiel à leur développement.
                      </span>
                    }
                    action={{
                      type: "internal",
                      route: "/inscription",
                      label: "Postuler",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={4}>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678919242/Capture_d_e%CC%81cran_2023-03-15_a%CC%80_23.27.15_cbdyjc.png"
                    label=""
                    title={<span className="fontKBoldGold">Agent de Services Hospitaliers (ASH) /ASL</span>}
                    description={
                      <span className="fontParaPetitWhite" style={{textAlign:"right"}}>
                      Le métier d'ASH implique la prise en charge des tâches de nettoyage, d'entretien et d'hygiène au sein d'établissements tels que les hôpitaux et les EHPAD. Les ASH jouent un rôle essentiel en maintenant un environnement propre et sûr pour les patients et le personnel médical, contribuant ainsi au bon fonctionnement des soins de santé. Ils sont également souvent en contact avec les patients, leur offrant un soutien en termes de confort et de bien-être.                      </span>
                    }
                    action={{
                      type: "internal",
                      route: "/inscription",
                      label: "Postuler",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678981294/original_frkkea.avif"
                    label=""
                    title={<span className="fontKBoldGold">Travailler en tant que médecin remplaçant</span>}
                    description={
                      <span className="fontParaPetitWhite">
                       Le médecin remplaçant est essentiel pour garantir que les soins médicaux ne connaissent pas d'interruption quel que soit le domaine de spécialité. Il incarne les valeurs d’engagement envers le bien-être des patients et d’intégrité professionnelle. Dans le contexte actuel de pénurie de soignants, les médecins sont des acteurs clés pour combler cette lacune et répondre aux besoins croissants de la population en matière de soins médicaux.
                      </span>
                    }
                    action={{
                      type: "internal",
                      route: "/inscription",
                      label: "Postuler",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678981394/accompagnement-du-createur-min_h5ntym.jpg"
                    label=""
                    title={<span className="fontKBoldGold">Pharmacien remplaçant</span>}
                    description={
                      <span className="fontParaPetitWhite">
                      Depuis la crise du COVID-19, le rôle du pharmacien s’est considérablement élargi, occupant désormais une place primordiale dans la chaîne de soins. En plus d’assurer la délivrance des médicaments et de précieux conseils aux patients, ses missions englobent aujourd’hui, la promotion de la santé, la prévention des maladies et davantage d’éducation des patients. Cette évolution de leur rôle les rend plus indispensables que jamais dans le système de soin de notre pays !                       </span>
                    }
                    action={{
                      type: "internal",
                      route: "/inscription",
                      label: "Postuler",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={4}>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678919242/Capture_d_e%CC%81cran_2023-03-15_a%CC%80_23.27.15_cbdyjc.png"
                    label=""
                    title={<span className="fontKBoldGold">Assistant(e) dentaire</span>}
                    description={
                      <span className="fontParaPetitWhite" style={{textAlign:"right"}}>L’assistant dentaire joue un rôle essentiel en assistant les dentistes lors des différents actes de soins prodigués. Ils assurent la gestion des instruments et offrent un soutien rassurant aux patients. Dans un environnement ou la santé bucco-dentaire constitue un élément clé de la santé globale, ils sont à l’avant-garde de la promotion de la santé bucco-dentaire et peuvent anticiper un rôle encore plus significatif à l’avenir en contribuant à des soins de qualité pour tous.</span>
                    }
                    action={{
                      type: "internal",
                      route: "/inscription",
                      label: "Postuler",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678981294/original_frkkea.avif"
                    label=""
                    title={<span className="fontKBoldGold">Audioprothésiste</span>}
                    description={
                      <span className="fontParaPetitWhite">
                        L’audioprothésiste détient une expertise importante dans l’amélioration de la qualité de vie auditive de ses patients. Il évalue les besoins auditifs, fournit des solutions sur mesure et offre un soutien essentiel pour une meilleure communication. Dans le contexte actuel où la sensibilisation à la santé auditive est en croissance constante, les opportunités dans ce domaine promettent de rester en expansion !                       </span>
                    }
                    action={{
                      type: "internal",
                      route: "/inscription",
                      label: "Postuler",
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={4}>
                  <ColoredBackgroundCard
                    color="dark"
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1678981394/accompagnement-du-createur-min_h5ntym.jpg"
                    label=""
                    title={<span className="fontKBoldGold">Pharmacien remplaçant</span>}
                    description={
                      <span className="fontParaPetitWhite">
                      Depuis la crise du COVID-19, le rôle du pharmacien s’est considérablement élargi, occupant désormais une place primordiale dans la chaîne de soins. En plus d’assurer la délivrance des médicaments et de précieux conseils aux patients, ses missions englobent aujourd’hui, la promotion de la santé, la prévention des maladies et davantage d’éducation des patients. Cette évolution de leur rôle les rend plus indispensables que jamais dans le système de soin de notre pays !                       </span>
                    }
                    action={{
                      type: "internal",
                      route: "/sections/page-sections/applications",
                      label: "Postuler",
                    }}
                  />
                </Grid> */}
              </Grid>
            </Container>
          </MKBox>
          {/* Nos valeurs */}


           
          {/* FAQ */}
          {/* <MKBox component="section" py={8}>
            <Container>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={6} my={6}>
                  <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
                    <span className="fontKionaGold">Questions fréquentes</span>
                  </MKTypography>
                  <MKBox mb={2}>
                    <MKTypography variant="body1" align="center" color="text">
                      <span className="fontPara">
                        Vous pouvez également nous contacter par email pour d&apos;autres questions:
                        contact@tablaba.com
                      </span>
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FaqCollapse
                    title={<span className="fontBold">Question 1</span>}
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 2</span>}
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 3</span>}
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 4</span>}
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                  <FaqCollapse
                    title={<span className="fontBold">Question 5</span>}
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                    <span className="fontPara">
                      We&apos;re not always in the position that we want to be at. We&apos;re
                      constantly growing. We&apos;re constantly making mistakes. We&apos;re
                      constantly trying to express ourselves and actualize our dreams. If you have
                      the opportunity to play this game of life you need to appreciate every moment.
                      A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    </span>
                  </FaqCollapse>
                </Grid>
              </Grid>
            </Container>
          </MKBox> */}
          {/* <Features /> */}
          {/* Nous contacter */}
          <div style={{marginTop:"110px"}} ref={refContact}>
          <Form typeForm={1} etab={"Cabinet de ville"}/>
          </div>
        </Card>
        <DefaultFooter content={footerRoutes} />
      </div>
    </>
  );
}

export default HelpCenter;
