// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedinIcon from "@mui/icons-material/LinkedIn";
import InstaIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Ephad from "pages/ephad";
import Hopital from "pages/hopital";
import Cabinet from "pages/cabinet";
import Pharmacie from "pages/pharmacie-officine";
import Annonce from "pages/annonce";
// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Material Kit 2 PRO",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/",
    },
    {
      icon: <LinkedinIcon />,
      link: "https://linkedin.com/",
    },
    {
      icon: <InstaIcon />,
      link: "https://github.com/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/",
    },
  ],
  menus: [
    {
      name: "Recruter",
      items: [
        { name: "EHPAD",
          route: "/ephad",
          component: <Ephad />, },
        { name: "Cabinet", route: "/cabinet",
        component: <Cabinet />, },
        { name: "Hôpital", route: "/hopital",
        component: <Hopital /> },
        { name: "Pharmacie", route: "/pharmacie-officine",
        component: <Pharmacie /> },
      ],
    },
    {
      name: "Candidats",
      items: [
        { name: "Etudiant", href: "https://sorya.eu" },
        { name: "Professionnel de santé", href: "https://sorya.eu" },
        { name: "Missions disponibles", href: "https://sorya.eu" },
      ],
    },
    {
      name: "Autre",
      items: [
        { name: "Notre mission", href: "https://sorya.eu" },
        { name: "Conditions tarifaires ", href: "https://sorya.eu" },
        { name: "Aide", href: "https://sorya.eu" },
      ],
    },
    {
      name: "Légale",
      items: [
        { name: "Mentions légales", href: "https://sorya.eu" },
        { name: "CGU", href: "https://sorya.eu" },
        { name: "Confidentialité", href: "https://sorya.eu" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
       Tous Droits Réservés. Copyright &copy; {date} Sorya
      {/* <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Creative Tim
      </MKTypography> */}
      .
    </MKTypography>
  ),
};
