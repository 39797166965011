import { initializeApp } from "firebase/app";
import { doc, getFirestore,getDoc } from "firebase/firestore";
import { addDoc, setDoc,Timestamp } from "firebase/firestore"; 
import { collection, query, where, getDocs } from "firebase/firestore";
import { sha256 } from 'js-sha256';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqiMdPh4TPURP1oCTD0AKliya2cPU_MMM",
  authDomain: "sorya-afe4d.firebaseapp.com",
  projectId: "sorya-afe4d",
  storageBucket: "sorya-afe4d.appspot.com",
  messagingSenderId: "167400666312",
  appId: "1:167400666312:web:5c5ddfd40aa842585721fd",
  measurementId: "G-BVVVXC7T8E"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);



const AirtablePlus = require('airtable-plus')

const API_KEY = 'patX4vPCGVYhRaE9O.d03c8b801c409959c00c4d894a26c3c6b32a1159c9f97fd5e69eb36ec386463c'
const BASE = 'app1sARYMk7I8bJ4X'

const TABLE_VI = 'V/I'
const TABLE_SAV = 'SAV'
const TABLE_HEURES = 'Bilan'

const TABLE_FACTURES = 'Factures'
const TABLE_ANNONCES = 'Annonces'
const TABLE_CONTACT = 'Contact Site'
const TABLE_ETABLISSEMENTS = 'Etablissements'

const airtable_vi = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: TABLE_VI
})
const airtable_sav = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: TABLE_SAV
})
const airtable_contact = new AirtablePlus({
    baseID: BASE,
    apiKey: API_KEY,
    tableName: TABLE_CONTACT
  })
const airtable_inscription = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: TABLE_VI
})
const airtable_etablissement = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: TABLE_ETABLISSEMENTS
})
/* const airtable_clients = new AirtablePlus({
    baseID: BASE,
    apiKey: API_KEY,
    tableName: TABLE_CLIENTS,
}); */
const airtable_bilan = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: TABLE_HEURES
})
const airtable_factures = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: TABLE_FACTURES
})
const airtable_annonces = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: TABLE_ANNONCES
})

export var intervenant = {
  id: '',
  nom: '',
  status: '',
  email: '',
  mot_de_passe: '',
  photo_de_profil: '',
  ville: '',
  adresse_postale: '',
  date_de_naissance: '',
  etudiant: '',
  cursur: '',
  annee_etude: '',
  implication: '',
  carte_identite: '',
  carte_vitale: '',
  certificat_scolarite: '',
  iban: '',
  justificatif_domicile: '',
  contrat: '',
  etablissements: [],
  factures: [],
  annonces: [],
  bilan: [],
  graph: [],
  notifications: false
}
export var annonces = []
export var mois = [[], [], [], [], [], [], [], [], [], [], [], []]
export var allEvents = [
  {
    id: 1,
    url: '',
    title: 'Dign Review',
    start: '2020-12-01T09:00:00',
    end: '2020-12-01T10:00:00',
    allDay: false,
    extendedProps: {
      calendar: 'Business'
    }
  }
]

/* LOGIN */
export async function contact(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,vousCherchez,typeContrat,message) {
  const docRef = await addDoc(collection(db, "contact-site"), {
    email:email,
    formulaire:formulaire,
    nom: nom ,
    prenom: prenom,
    nomEtablissement:nomEtablissement,
    telephone: telephone,
    codePostal: codePostal,
    cursus:cursus == "" ? "Non renseigné" : cursus,
    departement:departement,
    etudiant:etudiant,
    annee:annee == "" ? "Non renseigné" : annee,
    typeEtablissement: typeEtablissement == "" ? "Non renseigné" : typeEtablissement,
    posteEtablissement:posteEtablissement,
    vousCherchez: vousCherchez[0] == "" ? ["Non renseigné"] : vousCherchez,
    message:message,
    typeContrat:typeContrat,
    status:'En attente',
    time: Timestamp.fromDate(new Date())
  });
  //console.log("Document written with ID: ", docRef.id);
}
export async function contact_v2(data,typeFor) {
  const docRef = await addDoc(collection(db, "contact-site"), {
    data,
    status:'En attente',
    type:typeFor,
    time: Timestamp.fromDate(new Date())
  });
  //console.log("Document written with ID: ", docRef.id);
}


export async function contact2(email,formulaire,nom,prenom,nomEtablissement,telephone,codePostal,cursus,departement,etudiant,annee,typeEtablissement,posteEtablissement,vousCherchez,typeContrat,message) {

    const res = await airtable_contact.create(
        { 
            'E-mail':email, 
            'Formulaire':formulaire,
            'Nom': nom , 
            'Prénom': prenom, 
            "Nom de l'établissement":nomEtablissement,
            'Téléphone': telephone,
            'Code postal': codePostal,
            'Cursus':cursus == "" ? "Non renseigné" : cursus,
            'Département':departement,
            'Etudiant':etudiant,
            "Année d'étude":annee == "" ? "Non renseigné" : annee,
            "Type d'établissement": typeEtablissement == "" ? "Non renseigné" : typeEtablissement,
            "Poste dans l'établissement":posteEtablissement,
            "Vous recherchez": vousCherchez[0] == "" ? ["Non renseigné"] : vousCherchez,
            "Message":message,
            'Status':'En attente'
        });            
    return res
}

export async function create_annonce2(nom,prenom,email,mot_de_passe,departement,cursus) {
  const docRef = await addDoc(collection(db, "annonces"), {
    nom: nom ,
    prenom: prenom,
    email:email,
    mot_de_passe: mot_de_passe,
    departement:departement,
    cursus:cursus,
    time: Timestamp.fromDate(new Date())
  });
}

export async function create_annonce(nom,prenom,codePostal,nomEtablissement,telephone,email,vousCherchez,typeContrat,message) {
  const docRef = await addDoc(collection(db, "annonces"), {
    nom: nom ,
    prenom: prenom,
    codePostal:codePostal,
    nomEtablissement:nomEtablissement,
    telephone: telephone,
    email:email,
    vousCherchez: vousCherchez,
    typeContrat:typeContrat,
    message:message,
    time: Timestamp.fromDate(new Date())
  });
  //console.log("Document written with ID: ", docRef.id);
}
export async function get_annonce() {
  const annonces = [];
  const q = query(collection(db, "annonces"), where("email", "==", "meyergrego@gmail.com"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    annonces.push(doc.data())
  })
  return annonces
}
export async function create_annonce21(nom,prenom,email,mot_de_passe,departement,cursus) {

  const res = await airtable_annonces.create(
      { 
          'Email':email, 
          'Prénom': prenom, 
          'Nom': nom , 
          'Mot de passe': mot_de_passe,
          'Département': departement,
          'Cursus': cursus,

      });            
  return res
}

export async function inscription_site(nom,prenom,email,mot_de_passe,departement,cursus) {
  const usersRef = doc(db, "utilisateurs", email);
  const docSnap = await getDoc(usersRef);
  console.log(docSnap.exists());
  if(docSnap.exists()){
    return false
  }else{
  const docRef = await setDoc(doc(db, "utilisateurs",email), {
    nom: nom ,
    prenom: prenom,
    email:email,
    mot_de_passe: mot_de_passe,
    departement:departement,
    cursus:cursus,
    time: Timestamp.fromDate(new Date())
  });
  const auth = getAuth();
  createUserWithEmailAndPassword(auth, email, mot_de_passe)
    .then((userCredential) => {
      const user = userCredential.user;
    })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  })
  return true
  }
}

export async function inscription_site2(nom,prenom,email,mot_de_passe,departement,cursus) {

  const res = await airtable_inscription.create(
      { 
          'Nom (site)':nom,
          'Titre': vousCherchez,
          'Email':email, 
          'Prénom': prenom, 
          'Nom': nom , 
          'Mot de passe': mot_de_passe,
          'Département': departement,
          'Cursus': cursus,

      });            
  return res
}

export async function inscription_etablissement_site(nom,email,mot_de_passe,ville,type,adresse,nomUser,prenomUser) {
/*   const docRef = await addDoc(collection(db, "etablissements"), {
    nom: nom ,
    email:email,
    mot_de_passe: mot_de_passe,
    ville:ville,
    type:type,
    adresse:adresse,
    time: Timestamp.fromDate(new Date())
  }); */

  const usersRef = doc(db, "etablissements", email);
  const docSnap = await getDoc(usersRef);
  if(docSnap.exists()){
    return false
  }else{
  const docRef = await setDoc(doc(db, "etablissements",email), {
    nom: nom ,
    email:email,
    status:'en attente',
    mot_de_passe: mot_de_passe,
    ville:ville,
    type:type,
    adresse:adresse,
    cover: "https://res.cloudinary.com/dbt1oxzwd/image/upload/v1709041264/Capture_d_e%CC%81cran_2024-02-04_a%CC%80_18.38.54_copie_q1cfsg.png",
    photo: "https://thinksport.com.au/wp-content/uploads/2020/01/avatar-.jpg",
    time: Timestamp.fromDate(new Date()),
    nom_user: nomUser,
    prenom_user: prenomUser,
    last_focus_msg: Timestamp.fromDate(new Date()),

  });
  const auth = getAuth();
  createUserWithEmailAndPassword(auth, email, mot_de_passe)
    .then((userCredential) => {
      const user = userCredential.user;
    })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  })
  return true
  }
}

export async function inscription_etablissement_site2(nom,email,mot_de_passe,ville,type,adresse) {

  const res = await airtable_etablissement.create(
      { 
          'E-mail':email, 
          'Nom': nom , 
          'Mot de passe': mot_de_passe,
          'Ville': ville,
          'Type': type,
          'Adresse': adresse,
          
      });            
  return res
}

export async function connexion(user, password) {
  const filter = "({Email} = '" + user + "')"
  try {
    const res = await airtable_vi.read({ maxRecords: 1, filterByFormula: filter })
    if (res.lenght !== 0) {
      const mot_de_passe = res[0].fields['Mot de passe']
      if (mot_de_passe == password) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (e) {
    //console.log(e)
    return false
  }
}

export async function mot_de_passe_perdu(email, msg) {
  const res = await airtable_sav.create({ Email: email, Message: msg, 'Mot de Passe': true })
  return res
}

/* DATA INTERVENANT */
export async function get_data_intervenant(user) {
  reset_intervenant()
  const filter = "({Email} = '" + user + "')"
  const res = await airtable_vi.read({ filterByFormula: filter })
  intervenant.id = res[0].id
  intervenant.nom = res[0].fields['Name']
  intervenant.status = res[0].fields['Status']
  intervenant.email = res[0].fields['Email']
  intervenant.mot_de_passe = res[0].fields['Mot de passe']
  intervenant.photo_de_profil = res[0].fields['Photo de profil']
  intervenant.ville = res[0].fields['Ville']
  intervenant.adresse_postale = res[0].fields['Adresse']
  intervenant.date_de_naissance = res[0].fields['Date de naissance']
  intervenant.etudiant = res[0].fields['Etudiant']
  intervenant.cursur = res[0].fields['Cursus']
  intervenant.annee_etude = res[0].fields["Année d'étude"]
  intervenant.implication = res[0].fields['Implication']
  intervenant.carte_identite = res[0].fields["Carte d'identité"]
  intervenant.carte_vitale = res[0].fields['Carte vitale']
  intervenant.certificat_scolarite = res[0].fields['Certificat de scolarité']
  intervenant.iban = res[0].fields['IBAN']
  intervenant.justificatif_domicile = res[0].fields['Justificatif de domicile']
  intervenant.calendrier = res[0].fields['Calendrier']
  intervenant.mardi = res[0].fields['Mardi']
  intervenant.mercredi = res[0].fields['Mercredi']
}

export async function fetchAllCalendars() {
  const calendarPerUser = []
  const res = await airtable_vi.read()
  for (const line in res) {
    if (res[line].fields['Calendrier']) {
      calendarPerUser.push({
        user: res[line].fields['Name'],
        calendar: JSON.parse(res[line].fields['Calendrier'])
      })
    }
  }
  console.log(calendarPerUser)
  sortAllCaledars(calendarPerUser)
}
const s = '01-01-1970 00:03:44'
const d = new Date(s)
const convertStringToDateTime = (dateString, timeString) => {
  const date = dateString.split('-')
  const time = timeString.split(':')
  /* new Date(2023, 6, 20, 7, 0, 0) */
  const dateTime = new Date(date[0], date[1] - 1, date[2], time[0], time[1], 0)
  return dateTime
}

export async function sortAllCaledars(calendarPerUser) {
  allEvents = []
  let indice = 0
  for (let calendar in calendarPerUser) {
    indice++
    for (let calPers in calendarPerUser[calendar].calendar) {
      indice++
      for (let event in calendarPerUser[calendar].calendar[calPers]) {
        indice++
        if (
          calendarPerUser[calendar].calendar[calPers][event].Horaire !== undefined &&
          calendarPerUser[calendar].calendar[calPers][event].Horaire != ''
        ) {
          allEvents.push({
            id: indice,
            url: '',
            title: calendarPerUser[calendar].calendar[calPers][event].Status,
            start: convertStringToDateTime(
              calPers,
              calendarPerUser[calendar].calendar[calPers][event].Horaire.split(' - ')[0]
            ),
            end: convertStringToDateTime(
              calPers,
              calendarPerUser[calendar].calendar[calPers][event].Horaire.split(' - ')[1]
            ),
            allDay: false,
            extendedProps: {
              calendar: 'Business'
            }
          })
        }
      }
    }
  }
  console.log(allEvents)
  return allEvents
}

export async function get_etablissements(user) {
  intervenant.etablissements = []
  const filter = "({Email} = '" + user + "')"
  const res = await airtable_vi.read({ filterByFormula: filter })
  for (let k = 0; k < res[0].fields['Retenu Id'].length; k++) {
    intervenant.etablissements.push({
      id: res[0].fields['Retenu Id'][k],
      nom: res[0].fields['Retenu Nom'][k],
      type: res[0].fields['Retenu Type'][k],
      ville: res[0].fields['Retenu Ville'][k],
      adresse: res[0].fields['Retenu Adresse'][k],
      tarif: res[0].fields['Retenu Tarif'][k],
      periode: res[0].fields['Retenu Période'][k],
      frequence: res[0].fields['Retenu Fréquence'][k],
      jours: res[0].fields['Retenu Jours'][k],
      description: res[0].fields['Retenu Détails'][k]
    })
  }
}

/* export async function get_eleves_plus_info (user,eleve) {
    intervenant.eleves_plus_info = [];
    const filter = "({Professeur-string} = '" + user + "')";
    const res = await airtable_clients.read({  filteryFormula: filter});
    //console.log(res)
} */

export async function get_factures(user) {
  intervenant.factures = [
    {
      id: 0,
      name: 'Aucune facture',
      etage: '',
      code: '',
      prix: '',
      avatar: Images.invoice,
      status: 'Factures'
    }
  ]
  const filter = "({Email} = '" + user + "')"
  try {
    const res = await airtable_factures.read({ filterByFormula: filter })
    if (res.lenght !== 0) {
      for (let x in res) {
        intervenant.factures.push({
          id: res[x].Id,
          name: res[x].fields['Mois'],
          etage: res[x].fields['Date'],
          code: res[x].fields['Status'],
          prix: res[x].fields['Total'],
          avatar: Images.invoice,
          status: 'Factures'
        })
      }
    } else {
      return false
    }
  } catch (e) {
    //console.log(e)
    return false
  }
  return intervenant.factures
}

export async function get_historique(user, month, year) {
  intervenant.bilan = []
  const sorter = [{ field: "Date d'ajout", direction: 'desc' }]
  const filter = "AND({Email} = '" + user + "',{Mois} = '" + month + "',{Année} = '" + year + "')"
  try {
    var res = await airtable_bilan.read({ filterByFormula: filter, sort: sorter })
    if (res.lenght !== 0) {
      for (let x in res) {
        intervenant.bilan.push({
          id: res[x].fields['Id'],
          mission: res[x].fields['Mission'],
          etablissements: res[x].fields['Etablissement'],
          type: res[x].fields['Type'],
          date: res[x].fields['Date Format'],
          tarif: res[x].fields['Tarif'],
          periode: res[x].fields['Période']
        })
      }
    } else {
      return false
    }
  } catch (e) {
    //console.log(e)
    return false
  }
  return res
}

export async function get_graph(user, year) {
  intervenant.graph = []
  const filter = "AND({Email} = '" + user + "',{Année} = '" + year + "')"
  const res = await airtable_bilan.read({ filterByFormula: filter })
  for (let x in res) {
    intervenant.graph.push({
      id: res[x].fields['Id'],
      mission: res[x].fields['Mission'],
      etablissement: res[x].fields['Etablissement'],
      type: res[x].fields['Type'],
      date: res[x].fields['Date Format'],
      tarif: res[x].fields['Tarif']
    })
  }
  return res
}

export async function get_annonces() {
  annonces = []
  const res = await airtable_annonces.read({ maxRecords: 50 })
  //console.log(res[0].fields['Active'])
  for (let i in res) {
    if (res[i].fields['Active'] === true) {
      annonces.push({
        numero: String(res[i].fields['Numero']),
        active: res[i].fields['Active'],
        titre: res[i].fields['Titre'],
        etablissement: res[i].fields['Nom établissement'],
        ville: res[i].fields['Ville'],
        adresse: res[i].fields['Adresse'],
        description: res[i].fields['Détails'],
        frequence: res[i].fields['Fréquence'],
        tarif: res[i].fields['Tarif'],
        periode: res[i].fields['Période'],
        candidats: res[i].fields['Candidats'],
        candidatsNoms: res[i].fields['Candidats Noms'],
        retenuNoms: res[i].fields['Retenu Noms'],
        candidatsStatus: res[i].fields['Candidats Status'],
        candidatsPhoto: res[i].fields['Candidats Photo'],
        jours: res[i].fields['Jours'],
        type: res[i].fields['Type établissement'],
        status: res[i].fields['Status'],
        salle: res[i].fields['Salle'],
        creneau: res[i].fields['Créneau'],

        /* jour1: res[i].fields['Lundi'],
                jour2: res[i].fields['Mardi'],
                jour3: res[i].fields['Mercredi'],
                jour4: res[i].fields['Jeudi'],
                jour5: res[i].fields['Vendredi'],
                jour6: res[i].fields['Samedi'],
                jour7: res[i].fields['Dimanche'], */
        id: res[i].id
      })
      console.log(res[i].fields['Candidats Noms'])
    }
  }
  return annonces
}

export async function get_annonces_by_id(id) {
  const filter = "({Id} = '" + id + "')"
  const res = await airtable_annonces.read({ filterByFormula: filter })
  return res
}

/* SEND DATA */

/* export async function send_eleves (id,indice,etage,code,autre) {
    const etage_indice = 'Etage ' + indice.toString()
    const code_indice = 'Code ' + indice.toString()
    const autre_indice = 'Autre ' + indice.toString()
    const res = await airtable_prof.update(id, { 
        [etage_indice] : etage,
        [code_indice]: code,
        [autre_indice]: autre,
     });
    return res
} */

/* export async function send_notife_active (id) {
    const res = await airtable_prof.update(id, { 
        Notifications: true
     });
    return res
} */

export async function send_heure(intervenant, etablissement, date, mission, tarif, id) {
  const res = await airtable_bilan.create({
    Name: intervenant,
    Etablissement: etablissement,
    Date: date,
    Mission: mission,
    Tarif: tarif,
    V_id: [id]
  })
  return res
}

export async function send_factures(intervenant, id, total, file, file_name) {
  const res = await airtable_factures.create({
    Name: intervenant,
    V_id: [id],
    Statut: 'En attente',
    totalApp: total,
    Facture: [
      {
        url: file,
        filename: file_name
      }
    ]
  })
  return res
}

export async function send_intervenant_data(id, nom, email, mot_de_passe, iban, siret, adresse) {
  const res = await airtable_vi.update(id, {
    Name: nom,
    Email: email,
    'Mot de passe': mot_de_passe,
    IBAN: iban,
    Adresse: adresse
  })

  return res
}

export async function send_signature(id, uri) {
  const res = await airtable_vi.update(id, {
    Contrat_base64: uri,
    Contrat: true
  })
  return res
}

export async function send_candidature(num, id) {
  let filter = "({Numero} = '" + num + "')"
  const cand = await airtable_annonces.read({ filterByFormula: filter, maxRecords: 1 })
  const oldListCand = cand[0].fields['Candidats']
  const newListCand = []
  const idRecord = id
  for (let x in oldListCand) {
    newListCand.push(oldListCand[x])
  }
  newListCand.push(idRecord)
  if (newListCand.length > 0) {
    const res = await airtable_annonces.updateWhere(filter, {
      Candidats: newListCand
    })
  } else {
    const res = await airtable_annonces.updateWhere(filter, {
      Candidats: [id]
    })
  }
}

export async function send_message(email, msg) {
  const res = await airtable_sav.create({ Email: email, Message: msg, 'Mot de Passe': false })
  return res
}

/* APP DATA */

export async function utilisateur_application(user, bool) {
  const res = await airtable_vi.update(user.id, { Utilisateur: bool })
  return res
}

export function sortHistoMonth(list) {
  mois = [[], [], [], [], [], [], [], [], [], [], [], []]
  for (let x in list) {
    if (list[x]['fields']['Date Format'].includes('/01/') || list[x]['fields']['Date Format'].includes('/1/')) {
      mois[0].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/02/') || list[x]['fields']['Date Format'].includes('/2/')) {
      mois[1].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/03/') || list[x]['fields']['Date Format'].includes('/3/')) {
      mois[2].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/04/') || list[x]['fields']['Date Format'].includes('/4/')) {
      mois[3].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/05/') || list[x]['fields']['Date Format'].includes('/5/')) {
      mois[4].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/06/') || list[x]['fields']['Date Format'].includes('/6/')) {
      mois[5].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/07/') || list[x]['fields']['Date Format'].includes('/7/')) {
      mois[6].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/08/') || list[x]['fields']['Date Format'].includes('/8/')) {
      mois[7].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/09/') || list[x]['fields']['Date Format'].includes('/9/')) {
      mois[8].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/10/') || list[x]['fields']['Date Format'].includes('/10/')) {
      mois[9].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/11/') || list[x]['fields']['Date Format'].includes('/11/')) {
      mois[10].push(list[x]['fields'])
    } else if (list[x]['fields']['Date Format'].includes('/12/') || list[x]['fields']['Date Format'].includes('/12/')) {
      mois[11].push(list[x]['fields'])
    }
  }
  return mois
}

export function reset_intervenant() {
  intervenant = {
    id: '',
    nom: '',
    status: '',
    email: '',
    mot_de_passe: '',
    photo_de_profil: '',
    ville: '',
    adresse_postale: '',
    date_de_naissance: '',
    etudiant: '',
    cursur: '',
    annee_etude: '',
    implication: '',
    carte_identite: '',
    carte_vitale: '',
    certificat_scolarite: '',
    iban: '',
    justificatif_domicile: '',
    contrat: '',
    etablissement: [],
    factures: [],
    annonces: [],
    bilan: [],
    graph: [],
    notifications: false
  }
}

export async function delete_intervenant(id) {
  const res = await airtable_vi.update(id, {
    Adresse: 'del12cv67ub244'
  })
  return res
}
