// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useRef,useState} from "react";
import ReactPlayer from "react-player";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";
import MKButton from "components/MKButton";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import MuiLink from "@mui/material/Link";
import { useMediaQuery } from 'react-responsive'
import { Link } from "react-router-dom";
//import MKSocialButton from "components/MKSocialButton";
import Slider from 'infinite-react-carousel';
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import MKInput from "components/MKInput";
import Form from "components/FormContact"; 

import Divider from "@mui/material/Divider";
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
import team1 from "assets/images/team-3.jpg";
import team2 from "assets/images/marie.jpg";
import team3 from "assets/images/team-2.jpg";
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import behanceLogo from "assets/images/logos/gray-logos/logo-behance.svg";
import spotifyLogo from "assets/images/logos/gray-logos/logo-spotify.svg";
import coinbaseLogo from "assets/images/logos/gray-logos/logo-coinbase.svg";
import pinterestLogo from "assets/images/logos/gray-logos/logo-pinterest.svg";
import {db} from "../../firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore"; 

/* import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Information from "pages/presentation/sections/Information";
import DesignBlocks from "pages/presentation/sections/DesignBlocks";
import AuthPages from "pages/presentation/sections/AuthPages";
import Pages from "pages/presentation/sections/Pages";
import Testimonials from "pages/presentation/sections/Testimonials";
import Pricing from "pages/presentation/sections/Pricing";

// Presentation page components
import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers"; */

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import styles from "./mystyle.module.css";
import "./style.css";
import { Typography } from "@mui/material";

function Presentation() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 824px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const headerRefSec1 = useRef(null);
  const [typeForm, setTypeForm] = useState(0);
  const headerRef = useRef(null);
  const refContact = useRef(null);

  const TAGS = ['Collaboration', 'CDD', 'Remplacement', 'CDI', 'Etudiant','Stage',  'Reprise','Cabinet', 'Locaux', 'Matériel'];
  const DURATION = 20000;
  const ROWS = 1;
  const TAGS_PER_ROW = 8;

  const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
  const shuffle = (arr) => [...arr]/* .sort( () => .5 - Math.random() ) */;

  const InfiniteLoopSlider = ({children, duration, reverse = false}) => {
    return (
      <div className='loop-slider' style={{
          '--duration': `${duration}ms`,
          '--direction': reverse ? 'reverse' : 'normal'
        }}>
        <div className='inner'>
          {children}
          {children}
        </div>
      </div>
    );
  };

  const Tag = ({ text }) => (
    <span
   className='tag fontSlide'>{text}</span>
  );

  const handleClick = (typeForm) => {
    setTimeout(() => {
      setTypeForm(typeForm);
    }, 1000);
    refContact.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const asink = async () => {
    const querySnapshot = await getDocs(collection(db, "etablissements"));
    querySnapshot.forEach((doc) => {
    console.log(doc.data());
    });
  }

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.sorya.eu",
          label: "Connexion",
          color: "info",
        }}
        sticky
      />

       <MKBox
          component="section"
          display="grid"
          position="relative"
          minHeight={isDesktopOrLaptop ? "77vh" : ""}
          mr={{ xs: 0, lg: 0 }}
          mb={{ xs: 0, lg: 0 }}
          style={{ marginTop: isDesktopOrLaptop ? "-50px" : "0px"}}
          sx={{
            overflow: { xs: "hidden", lg: "visible" },
            placeItems: "center",
            backgroundColor: "#EDFAF8",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
          }}
          /* ref={headerRefSec1} */
          width="100%"
        >
          {isDesktopOrLaptop ? (
          <MKBox
          alt=""
          width={{ xs: "100%", lg: "50%" }}
          height={{ xs: "100%", lg: "100%" }}
          position="absolute"
          right={0}
          bottom={{ xs: "-25%", lg: "unset" }}
          top={{ xs: 0, lg: "unset" }}
          sx={{
            objectFit: "cover",
            borderTopLeftRadius: 0,
          }}
          //ref={headerRef}
        >
          <ReactPlayer
            playing="true"
            loop="true"
            volume="0"
            width="180%"
            height="900px"
            url="https://res.cloudinary.com/dbt1oxzwd/video/upload/v1694533377/bkg_hwacjz.mp4"
          />
        </MKBox>
          ) : null }
          <Container>
          
            <Grid
              container
              spacing={{ xs: 0, lg: 3 }}
              sx={{ mt: { xs: 0, lg: 12 } }}
              flexDirection="row"
            >
              <Grid item xs={12} lg={7} justifyContent="center" flexDirection="column">
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  shadow="lg"
                  borderRadius="xl"
                  style={{ borderWidth: "3px", marginTop: isDesktopOrLaptop ? "0px" : "120px" }}
                  sx={{
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                      rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    px: { xs: 3, sm: 0 },
                    py: { xs: 3, sm: 6 },
                    mb: { xs: 0, lg: 10 },
                    mt: { xs: 0, lg: 2 },
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    md={10}
                    justifyContent={{ xs: "center", md: "start" }}
                    sx={{ textAlign: { xs: "center", md: "left" } }}
                  >
                    <MKTypography
                      variant="h1"
                      color="dark"
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                        },
                      })}
                    >
                      <span className={styles.titleGrad}></span>
                    </MKTypography>
                    <MKTypography
                      variant="h3"
                      mt={0}
                      // pr={{ md: 12, lg: 24, xl: 32 }}
                      opacity={0.8}
                    >
                      <span className="sousTitre">
                     Echangez entre professionnels de santé.
                      </span>
                       <br />
                      <span className="sousTitre">
                     Gratuitement.
                      </span>
                      <br />
                      <span className="sousTitre">
                     Sans intermédiaires.
                      </span>
                     
                      </MKTypography>
                      <MKTypography
                      variant="body1"
                      mt={0}
                      style={{marginTop:"10px"}}
                      // pr={{ md: 12, lg: 24, xl: 32 }}
                      opacity={0.8}
                    >
                      <span className="fontGrey">
                      Vous avez toutes les cartes en main. Offrez-vous le luxe du choix et trouvez l’opportunité qui vous correspond grâce à Sorya, la plateforme réservée aux professionnels de santé.
                      </span>
                      <br />
                      </MKTypography>

                    <Stack direction={isDesktopOrLaptop ? "row" : "column"} style={{alignItems: isDesktopOrLaptop ? "left" : "center"}} spacing={2} mt={3} mb={1}>
                      <MKButton variant="contained" color="info" className="fontClassic" style={{width:"230px"}} onClick={()=>{/* asink(); */handleClick(0)}}>
                        <span style={{color:"#283451"}}>Je candidate</span>
                      </MKButton>

                      <MKButton variant="contained" color="warning" className="fontClassic"  style={{width:"230px"}} onClick={()=>{handleClick(1)}}>
                        <span style={{color:"#fff"}}>Je recrute</span>
                      </MKButton>
                    </Stack>
                  </Grid>
                </MKBox>
              </Grid>
            </Grid>

          </Container>
        </MKBox>



       <div className="divGen" style={{paddingTop: isDesktopOrLaptop ? "0px": "150px"}}>
        <Card
        
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: "#fff",
            // backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <div className='app'>

            <div className='tag-list'>
              {[...new Array(ROWS)].map((_, i) => (
                <InfiniteLoopSlider key={i} duration={random(DURATION - 5000, DURATION + 5000)} reverse={i % 2}>
                  {shuffle(TAGS).slice(0, TAGS_PER_ROW).map(tag => (
                    <Tag text={tag} key={tag}/> 
                    
                  ))}
                </InfiniteLoopSlider>
              ))}
              <div className='fade'/>
            </div>
          </div>
          {/* 3 features */}
          <MKBox component="section" py={8}>
            <Container>
              <Grid
                container
                item
                xs={12}
                lg={10}
                justifyContent="center"
                mx="auto"
                textAlign="center"
                pb={6}
              >
                

                <MKTypography variant="h1" mb={1} style={{marginTop:"-35px"}}>
                  <span className="fontThin">Le renouveau du recrutement en</span>
                  <br />
                  <span className="fontKionaGold">toute simplicité</span>
                </MKTypography>
                {/* <MKTypography variant="body1" color="text">
                  We&apos;re constantly trying to express ourselves and actualize our dreams. If you
                  have the opportunity to play{" "}
                </MKTypography> */}
              </Grid>
              <Grid container spacing={3}>
               
                <Grid item xs={12} md={6} lg={4}>
                  <SimpleInfoCard
                    color="dark"
                    icon="all_inclusive"
                    title={
                      <>
                        <span className="bigWordThin">Processus simple et efficace</span>
                        <br/>
                        {/* <span className="fontThin space">une solution de recrutement efficace</span> */}
                      </>
                    }
                    description={
                      <span className="fontParaPetit">
                        La publication d'offres et l'envoi de candidatures n'ont jamais été aussi simples !

                      </span>
                    }
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <SimpleInfoCard
                    color="dark"
                    icon="schedule"
                    title={
                      <>
                        <span className="bigWordThin">Sans agence</span>
                        <br/>
                        {/* <span className="fontThin"> sur les formalités administratives</span> */}
                      </>
                    }
                    description={
                      <span className="fontParaPetit">
                       Mettez fin aux intermédiaires de recrutement, créez un lien direct avec d'autres professionnels de santé
                      </span>
                    }
                    direction="center"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <SimpleInfoCard
                    color="dark"
                    icon="insights"
                    title={
                      <>
                        <span className="bigWordThin">Votre communauté de praticiens</span>
                        <br/>
                       {/*  <span className="fontThin">grâce à des conseillers attentifs</span> */}
                      </>
                    }
                    description={
                      <span className="fontParaPetit">
                        Elargissez votre réseau et renforcez votre visibilité en intégrant Sorya
                      </span>
                    }
                    direction="center"
                  />
                </Grid>

              </Grid>
            </Container>
          </MKBox>

          {/* Notre service */}
          {/* <MKBox component="section" py={0} mt={0}>
            <div id="serivces">
              <Container>
                <Grid container spacing={2} alignItems="center" sx={{ mt: -12 }}>
                  <Grid item xs={12} md={5.5} sx={{ ml: "auto" }}>
                    <DefaultBackgroundCard
                      image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694700475/Sans_titre_4_cnspfq.png"
                      label="Rapide"
                      title={<><span>Publier une annonce dès </span><br/><span>maintenant</span></>}
                      description=""
                      action={{
                        type: "internal",
                        route: "/annonce",
                        label: "Accédez au formulaire",

                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
                    <MKBox component="section" py={2}>
                      <Container>
                        <Grid container spacing={3} mb={6} mt={3}>
                          <Grid container spacing={3} item xs={12} md={6} lg={12}>
                            <Grid item xs={12} md={6}>
                              <RotatingCard>
                                <RotatingCardFront
                                  image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694537303/Untitled_13_nz7l5k.png"
                                  icon="apartment"
                                  title={<>EHPAD</>}
                                  color="dark"
                                  description="Voir plus"
                                />
                                <RotatingCardBack
                                  color="dark"
                                  image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694537303/Untitled_13_nz7l5k.png"
                                  title=""
                                  description="Trouver des vacataires/CDD/CDI Sorya"
                                  action={{ type: "internal", route: "/ehpad", label: "Voir plus" }}
                                />
                              </RotatingCard>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <RotatingCard>
                                <RotatingCardFront
                                  image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694537260/Untitled_12_v7qaee.png"
                                  icon="house"
                                  title={<>Cabinet de ville</>}
                                  color="dark"
                                  description="Voir plus"
                                />
                                <RotatingCardBack
                                  color="dark"
                                  image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694537260/Untitled_12_v7qaee.png"
                                  title=""
                                  description="Trouver un remplaçant avec Sorya"
                                  action={{ type: "internal", route: "/cabinet", label: "Voir plus" }}
                                />
                              </RotatingCard>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <RotatingCard>
                                <RotatingCardFront
                                  image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694537244/Sans_titre_2_ywmsiy.png"
                                  icon="local_pharmacy"
                                  title={<>Pharmacie</>}
                                  color="dark"
                                  description="Voir plus"
                                />
                                <RotatingCardBack
                                  color="dark"
                                  image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694537244/Sans_titre_2_ywmsiy.png"
                                  title=""
                                  description="Trouver des pharmaciens/préparateur/étudiants avec Sorya"
                                  action={{ type: "internal", route: "/pharmacie-officine", label: "Voir plus" }}
                                />
                              </RotatingCard>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <RotatingCard>
                                <RotatingCardFront
                                  image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695040933/Sans_titre_5_lyyqtw.png"
                                  icon="emergency"
                                  title={<>Hôpital</>}
                                  color="dark"
                                  description="Voir plus"
                                />
                                <RotatingCardBack
                                  color="dark"
                                  image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695040933/Sans_titre_5_lyyqtw.png"
                                  title=""
                                  description="Trouver des vacataires/CDD/CDI Sorya"
                                  action={{ type: "internal", route: "/hopital", label: "Voir plus" }}
                                />
                              </RotatingCard>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Container>
                    </MKBox>
                  </Grid>
                </Grid>
   
                
              </Container>
            </div>
          </MKBox> */}

          {/* Tablaba s'adapte */}
          {/* <MKBox
            variant="gradient"
            bgColor="dark"
            position="relative"
            borderRadius="xl"
            mx={{ xs: isDesktopOrLaptop ? 2 : -1, xl: 16, xxl: 16 }}
            mt={0}
            py={10}
            px={3}
            sx={{ overflow: "hidden",mt: isDesktopOrLaptop ? 12 :4 }}
          >
            <MKBox
              component="img"
              src={bgPattern}
              alt="pattern-lines"
              position="absolute"
              top={0}
              left={0}
              bgColor="#0F2022"
              width="100%"
              opacity={0.6}
            />
            <Container sx={{ position: "relative" }}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={7}
                  py={{ xs: 0, sm: 0 }}
                  mr="auto"
                  position="relative"
                >
                  <MKTypography variant="h2" color="white" mb={3} className="fontThin">
                    <span className="fontKThin"> Rejoins <span className="fontKionaGold2">la communauté Sorya </span>et accède à des missions autour de chez toi ! </span>
                   

                  </MKTypography>
                  <MKTypography variant="body1" color="white" mb={0}>
                  Une solution de long-terme qui s’adapte à toutes les étapes de ta carrière : étudiant, professionnel actif ou retraité.
                  </MKTypography>
                  <MKTypography variant="body1" color="white" mb={6}>
                Élargis ton réseau professionnel tout en développant rapidement tes compétences.
                  </MKTypography>
                 
                  <MKButton variant="gradient"  color="light" component={Link}
                  to={'/inscription'}>
                  L’aventure démarre ici
                  </MKButton>
  
                </Grid>
                <Grid item xs={12} position="absolute" left="60%" mr={-32} width="55%">
                  <MKBox
                    component="img"
                    src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694262978/Capture_d_e%CC%81cran_2023-09-09_a%CC%80_14.32.41-removebg-preview_zc07hw.png"
                    alt="macbook"
                    width="50%"
                    display={{ xs: "none", md: "block" }}
                  />
                </Grid>
              </Grid>
            </Container>
          </MKBox> */}

          {/* App mobile */}
          <MKBox style={{marginTop:70}} component="section" py={isDesktopOrLaptop ? 6 : 8}>
            <Container>
            { isDesktopOrLaptop ? null : (
                <Grid container justifyContent="center" alignItems="center" style={{marginBottom:"50px"}}>
                <img src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1694709344/1_copie_fop9jc.png" alt="image" width="100%" style={{borderRadius:"10px"}} />
                </Grid>
                )}
              <div style={{flexDirection:'row',justifyContent:'space-between',display:'flex'}}>   
                <div style={{width:'100%',marginRight:100}}>
                <Grid
                container
                item

                justifyContent="flex-strat"

                mx="auto"
                textAlign="left"

              >
                <span className='fontSlide2'>App candidat - App recruteur</span>
                <MKTypography variant="h2" mb={1} style={{marginTop:"5px"}}>
                  <span className="fontKionaGold">Rejoignez la communauté Sorya à travers une app intuitive</span>
                <br />
                 {/*  <span className="fontThin">Faites passer votre recrutement au</span> */}
                </MKTypography>
              </Grid>
              <span className="fontParaPetit">L'app Sorya a été pensée pour répondre aux attentes aussi bien des candidats que des recruteurs. Inscrivez-vous et accédez à des fonctionnalités exclusives qui vont au-delà du recrutement</span>

                  {/* <Grid container justifyContent="center">
                    <Grid item xs={10} md={5}>
                      <MKBox mb={5}>
                        <DefaultInfoCard
                        color="success"
                          icon="calendar_month"
                          title="Calendrier intuitif"
                          description="Renseigne tes créneaux disponibles et trouve des missions qui s’adaptent à ton emploi du temps"
                        />
                      </MKBox>
                    </Grid>
                    <Grid item xs={10} md={5}>
                      <MKBox mb={5}>
                        <DefaultInfoCard
                        color="success"
                          icon="trending_up"
                          title="Augmente tes revenus"
                          description="Centralise l’ensemble de tes revenus générés durant tes missions sur Sorya"
                        />
                      </MKBox>
                    </Grid>
                    <Grid item xs={10} md={5}>
                      <MKBox mb={{ xs: 5, md: 0 }}>
                        <DefaultInfoCard
                        color="success"
                          icon="3p"
                          title="Assistance personnalisée"
                          description="Décharge toi des formalités administratives et concentre toi sur ton activité grâce à Sorya !"
                        />
                      </MKBox>
                    </Grid>
                    <Grid item xs={10} md={5}>
                      <MKBox mb={{ xs: 5, md: 0 }}>
                        <DefaultInfoCard
                        color="success"
                          icon="workspace_premium"
                          title="Annonces exclusives "
                          description="Découvre des opportunités uniques à consulter directement sur l’application mobile"
                        />
                      </MKBox>
                    </Grid>
                    { isDesktopOrLaptop ? (
                    <>
                      <Grid item xs={12} md={5} py={6}>
                        <MKBox mb={{ xs: 5, md: 0 }}>
                        <img src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695039454/Apple3_deyxdv.gif" alt="image" width="50%" />
                        </MKBox>
                      </Grid>
                      <Grid item xs={12} md={5} py={6}>
                        <MKBox mb={{ xs: 5, md: 0 }}>
                        <img src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695039855/google-play-badge_lfqylx.png" alt="image" width="50%" />
                        </MKBox>
                      </Grid>
                    </>
                    ) : null }
                  </Grid> */}
                  <div style={{display:'flex',justifyContent:'flex-start',flexDirection:'row',marginTop:"40px"}}>

                  <Stack>
                    <AboutUsOption
                    color={"#3fc1c0"}
                      icon={1}
                      content={
                        <>
                          Publiez et consultez les annonces et profils disponibles
                          <br />
                          {/* existant sur le marché. */}
                        </>
                      }
                    />
                    <AboutUsOption
                     color={"#04a6c2"}
                      icon={2}
                      content={
                        <>
                          Paramètrez des alertes personnalisées
                          <br />
                         
                        </>
                      }
                    />
                    <AboutUsOption
                     color={"#16679a"}
                      icon={3}
                      content={
                        <>
                         Communiquez via une messagerie interne sécurisée
                          <br />
                         {/*  la date, la saisonnalité, la météo et les événements spéciaux. */}
                        </>
                      }
                    />
                    <AboutUsOption
                     color={"#1d4e89"}
                      icon={4}
                      content={
                        <>
                          Accédez à l'actualité du secteur depuis votre app Sorya
                          <br />
                         {/*  la date, la saisonnalité, la météo et les événements spéciaux. */}
                        </>
                      }
                    />
                  </Stack>
                </div>
                </div>
                { isDesktopOrLaptop ? (
                <Grid  style={{width:'400px'}} item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 3, lg: 2 } }}>
                  <img src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1715778554/soryApp1_ehryjf.png" style={{width:"500px",height:'auto'}} />
                  {/* <CenteredBlogCard
                 
                    image="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1715778554/soryApp1_ehryjf.png"
                    title=""
                    description=""
                    action={{ type: "internal", route: "/", label: "Télécharger" }}
                  /> */}
                </Grid>
                ) : null }
              </div>
            </Container>
          </MKBox>

          {/* Témoignages */}
          <MKBox component="section" py={6}>
            <Container>
            <Grid
                container
                item
                xs={12}
                lg={10}
                justifyContent="center"
                mx="auto"
                textAlign="center"
                pb={6}
              >
                

                <MKTypography variant="h2" mb={1} style={{marginTop:"-30px"}}>
                  <span className="fontThin">Avis utilisateurs</span>
                  <br />
                  <span className="fontKionaGold">Ils en parlent mieux que nous...</span>
                </MKTypography>
                {/* <MKTypography variant="body1" color="text">
                  We&apos;re constantly trying to express ourselves and actualize our dreams. If you
                  have the opportunity to play{" "}
                </MKTypography> */}
              </Grid>
              <Grid container item xs={12} lg={6} justifyContent="center" mx="auto" textAlign="center">
                {/* <MKTypography variant="h2" mb={2}>
                  <span className="bigWordThin2">Témoignages</span>
                </MKTypography> */}
                {/* <MKTypography variant="body2" color="text" mb={2}>
                  That&apos;s the main thing people are controlled by! Thoughts - their perception of
                  themselves!{" "}
                </MKTypography> */}
              </Grid>
              <Grid container spacing={3} mt={8}>
                <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
                  <DefaultReviewCard
                    image={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695290701/te%CC%81le%CC%81chargement_5_ejb8jd.jpg"}
                    name="Martin"
                    date="Interne en radiologie"
                    review="L' application permet de trouver facilement des remplacements en fonction de mon emploi du temps.
                    Je peux m'organiser comme j'en ai envie"
                    rating={4}
                  />
                </Grid>
                
                <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
                  <DefaultReviewCard
                    color="dark"
                    image={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695290871/te%CC%81le%CC%81chargement_4_jemymq.jpg"}
                    name="Julie"
                    date="Dentiste"
                    review="L' app m'a permis de trouver ma
                    première collaboration en accédant facilement à un grand nombre
                    d'offres sérieuses"
                    rating={5}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
                  <DefaultReviewCard
                    image={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1695290701/te%CC%81le%CC%81chargement_6_rec6jw.jpg"}
                    name="Meriem"
                    date="Médecin généraliste"
                    review="Avec Sorya, nous avons pu trouver facilement un nouveau
                    collaborateur pour notre cabinet sans perdre de temps et sans nous
                    ruiner"
                    rating={5}
                  />
                </Grid>
              </Grid>
              {/* <Divider sx={{ my: 6 }} /> */}
              {/* <Grid container spacing={3} justifyContent="center">
                <Grid item xs={6} lg={2}>
                  <MKBox component="img" src={appleLogo} alt="apple" width="100%" opacity={0.6} />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <MKBox component="img" src={facebookLogo} alt="facebook" width="100%" opacity={0.6} />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <MKBox component="img" src={behanceLogo} alt="behance" width="100%" opacity={0.6} />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <MKBox component="img" src={spotifyLogo} alt="spotify" width="100%" opacity={0.6} />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <MKBox component="img" src={coinbaseLogo} alt="coinbase" width="100%" opacity={0.6} />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <MKBox component="img" src={pinterestLogo} alt="pinterest" width="100%" opacity={0.6} />
                </Grid>
              </Grid> */}
            </Container>
          </MKBox>

         
          {/* Associations */}
          { isDesktopOrLaptop ? (
          <MKBox component="section" position="relative" py={16}>
            <Container sx={{ maxWidth: "100% !important" }}>
              <Grid container item xs={8} sx={{ mx: "auto" }}>
                <MKBox variant="gradient" bgColor="warning" borderRadius="lg" width="100%" py={6}>
                  <Grid container>
                    <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 5 }}>
                      <MKBox
                        component="img"
                        src="https://res.cloudinary.com/dbt1oxzwd/image/upload/v1716814010/AdobeStock_611977900_lwazck.jpg"
                        alt="image"
                        borderRadius="md"
                        maxWidth="300px"
                        width="100%"
                        position="relative"
                        mt={0}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      xl={7}
                      sx={{
                        position: "relative",
                        px: { xs: 6, md: 2 },
                        mt: { xs: 3, md: 0 },
                        my: { xs: 0, md: "auto" },
                        color: ({ palette: { white } }) => white.main,
                      }}
                    >
                      <VolunteerActivismIcon fontSize="large" color="info" />
                      <MKTypography variant="h3" my={1} mb={3} color="info">
                      
                          <span className="fontKionaBlue">NOTRE MISSION</span>

                      </MKTypography>
                      {/* <MKTypography
                        variant="h2"
                        color="white"
                        fontWeight="light"
                        mb={2}
                        sx={{ fontSize: "1.525rem" }}
                      >
                        <span className="fontKThin">
                        Apporter notre soutien aux établissements de santé pour qu'ils puissent assurer la continuité des soins. Avec Sorya, nous leur permettons de proposer une formule attractive et flexible pour les professionnels de santé.
                        </span>
                      </MKTypography> */}
                      <MKTypography
                        variant="body1"
                        color="white"
                        fontWeight="light"
                        mb={2}
                        sx={{ fontSize: "1.125rem" }}
                      >
                        <span className="fontParaWhite">
                        Dans un contexte de pénurie de professionnels de santé qui n'épargne aucune profession et aucune région, l'appariement entre les professionnels de santé et les structures de soins devient un enjeu majeur.
                        </span>
                      </MKTypography>
                      <MKTypography
                        variant="body1"
                        color="white"
                        fontWeight="light"
                        mb={2}
                        sx={{ fontSize: "1.125rem" }}
                      >
                        <span className="fontParaWhite">
                        Bien que diverses solutions existent, elles présentent des inconvénients majeurs : certaines, comme l'intérim ou les agences de recrutement, sont trop onéreuses pour un usage fréquent, tandis que d'autres, telles que le bouche à oreille ou les réseaux sociaux, s'avèrent trop chronophages et insuffisamment efficaces.
                        </span>
                      </MKTypography>
                      <MKTypography
                        variant="body1"
                        color="white"
                        fontWeight="light"
                        mb={2}
                        sx={{ fontSize: "1.125rem" }}
                      >
                        <span className="fontParaWhite">
                        Face a ces constats, nous nous sommes donnés pour mission d'offrir une plateforme ouverte à tous, gratuite, et pensée pour répondre efficacement aux besoins des professionnels de santé et des structures de soins.                        </span>
                      </MKTypography>
                      {/* <MKTypography
                        variant="body1"
                        color="white"
                        fontWeight="light"
                        mb={2}
                        sx={{ fontSize: "1.125rem" }}
                      >
                        <span className="fontPara">
                        Autour de cette communauté interdisciplinaire grandissante, nous aspirons à redéfinir les relations avec les structures de santé en facilitant la mobilité des soignants, en proposant autant d’avantages que possible et en recréant un lien direct avec les établissements de santé.</span>
                      </MKTypography> */}
                    </Grid>
                    <Grid item xs={1} />
                    
                  </Grid>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>
          ) : null }
          {/* Contact */}
          {/* <MKBox component="section" py={4}>
            <Container>
              <Grid
                container
                item
                justifyContent="center"
                xs={10}
                lg={7}
                mx="auto"
                mb={{ xs: 0, md: 6 }}
                textAlign="center"
              >
                <MKTypography variant="h1" mb={1}>
                  <span className="fontKionaGold">Nous Contacter</span>
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  <span className="fontPara">
                    Nous tâchons de répondre dans les plus brefs délais à vos demandes.
                  </span>
                </MKTypography>
              </Grid>
              <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
                <MKBox width="100%" component="form" method="post" autocomplete="off">
                  <MKBox p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKInput success label={<span className="fontForm">Nom</span>} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          success
                          type="email"
                          label={<span className="fontForm">Email</span>}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          success
                          label={<span className="fontForm">Email</span>}
                          multiline
                          fullWidth
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={6}>
                      <MKButton type="submit" variant="outlined" color="goldCustom">
                        Envoyer
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Container>
          </MKBox> */}
   
          <div ref={refContact}>
          {/* <Form typeForm={typeForm}/> */}
          {/* <Form typeForm={0}/>
          <Form typeForm={1}/> */}

          {typeForm === 0 && <Form typeForm={0} etab={""}/>}
          {typeForm === 1 && <Form typeForm={1} etab={""}/>}
          </div>
        </Card>
        <DefaultFooter content={footerRoutes} />
      </div>

    
        
    
    </>
  );
}

export default Presentation;
